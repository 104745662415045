import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useSelector,useDispatch } from "react-redux";
import { useEffect } from 'react';
import { useWeb3React } from "@web3-react/core";
import { asyncSaveAfterMint } from "../../store/serverSlice";

const PurchaseResultEl = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 64px 0px 48px 0px;
  }
`;

const PurchaseResultTitle = styled.span`
  color: var(--black, #000);

  font-family: Parabole;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 112.5% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    text-align: center;
  }
`;

const TicketImg = styled.img`
  width: 384px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 248px;
  }
`;

const OpenseaButton = styled.button`
  color: var(--black, #000);
  padding: 6px 24px;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1.12px;
  text-transform: uppercase;

  background: none;
  border-radius: 2000px;
  border: 1px solid var(--black, #000);

  cursor: pointer;
`;

function TicketPurchaseResult({ userName, userMail, userCountry }) {
  const tokenId = useSelector((state) => state.mint.tokenId);
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(asyncSaveAfterMint({ userName, userMail, userCountry, account }));
  },[]);
  return (
    <PurchaseResultEl>
      <PurchaseResultTitle>THANK YOU FOR YOUR PURCHASE</PurchaseResultTitle>
      <TicketImg src={"/images/ticket.png"} />
      <a
        href={"https://opensea.io/assets/ethereum/"+process.env.REACT_APP_CONTRACT_ADDRESS+"/"+tokenId}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <OpenseaButton>VIEW ON OPENSEA</OpenseaButton>
      </a>
    </PurchaseResultEl>
  );
}
export default TicketPurchaseResult;
