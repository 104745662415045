import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFirst: true,
  isScrollSmooth: true,
  isMenifestoOpen: false,
  scrollY: 0,
};

const componentSlice = createSlice({
  name: "component",
  initialState: initialState,
  reducers: {
    setIsFirst(state, action) {
      state.isFirst = action.payload;
    },
    setIsScrollSmooth(state,action){
      state.isScrollSmooth = action.payload;
    },
    setIsMenifestoOpen(state, action){
      state.isMenifestoOpen = action.payload;
    },
    setScrollY(state, action) {
      state.scrollY = action.payload;
    },
  },
});

export const componentActions = componentSlice.actions;
export default componentSlice.reducer;
