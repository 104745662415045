import MainPage from "./pages/MainPage";
import { GlobalStyle1, GlobalStyle2, GlobalStyle3 } from "./styles/GlobalStyle";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Web3 from "web3";
import ContractInfo from "./contracts/CAS2023.json";

function App() {
  if (true) {
    console.log = function no_console() {};
    console.warn = function no_console() {};
  }
  const [mintCount, setMintCount] = useState(0);
  const [showMenifesto, setShowMenifesto] = useState(false);
  const isMinting = useSelector((state) => state.mint.isMinting);
  const ABI = ContractInfo.abi;
  const web3 = new Web3(
    new Web3.providers.HttpProvider(process.env.REACT_APP_RPC)
  );
  const contract = new web3.eth.Contract(
    ABI,
    process.env.REACT_APP_CONTRACT_ADDRESS
  );

  useEffect(() => {
    async function getMintCount() {
      const result = await contract.methods.mintcount().call();
      setMintCount(parseInt(result));
    }
    getMintCount();
  }, []);
  useEffect(() => {
    async function getMintCount() {
      const result = await contract.methods.mintcount().call();
      setMintCount(parseInt(result));
    }
    getMintCount();
  }, [isMinting]);

  const isScrollSmooth = useSelector((state) => state.component.isScrollSmooth);

  return (
    <>
      {/* {isScrollSmooth || showMenifesto ? <GlobalStyle1 /> : <GlobalStyle2 />}
      {showMenifesto && <GlobalStyle2 />} */}
      {isScrollSmooth ? (showMenifesto ? <GlobalStyle2 /> : <GlobalStyle1 />) : (showMenifesto ? <GlobalStyle2 /> : <GlobalStyle3 />)}
      <MainPage
        mintCount={mintCount}
        setShowMenifesto={setShowMenifesto}
        showMenifesto={showMenifesto}
      />
    </>
  );
}

export default App;
