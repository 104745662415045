import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const VisitPartEl = styled.div`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: flex;
    flex-direction: column;
    margin-top: 33px;
    gap: 80px;
  }
`;

const VisitImg = styled.img`
  width: 100%;
  height: auto;
`;

const VisitItmeEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const Title = styled.span`
  color: #fff;
  font-family: Parabole;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: uppercase;
  transition: 1s;
  clip-path:${(props)=>(props.$isShow ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)")};
  transform:${(props)=>(props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 40px;
    line-height: 48px; /* 120% */
  }
`;

const Explain = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const Location = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: -0.6px;
  text-transform: uppercase;
`;

const VisitButton = styled.button`
  display: flex;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  border-radius: 2000px;
  border: 1px solid #fff;
  background: none;

  color: #fff;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
`;

const SuttleInfoEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SuttleInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;

const SuttleMap = styled.a`
  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  text-decoration: underline;
  color: #FFF;
`;

const SiteLink = styled.a`
  text-decoration: none;
`;

function VisitPartMobile() {
  const [isTitle1Show, setIsTitle1Show] = useState(false);
  const [title1Ref, title1InView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [isTitle2Show, setIsTitle2Show] = useState(false);
  const [title2Ref, title2InView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [isTitle3Show, setIsTitle3Show] = useState(false);
  const [title3Ref, title3InView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (title1InView) {
      setIsTitle1Show(true);
    }
  }, [title1InView]);

  useEffect(() => {
    if (title2InView) {
      setIsTitle2Show(true);
    }
  }, [title2InView]);

  useEffect(() => {
    if (title3InView) {
      setIsTitle3Show(true);
    }
  }, [title3InView]);
  return (
    <>
      <VisitPartEl>
        <VisitItmeEl>
          <VisitImg src={"/images/visit_1.png"} />
          <Title ref={title1Ref} $isShow={isTitle1Show}>Location</Title>
          <Explain>CRYPTO ART SEOUL 2023 will take place at:</Explain>
          <Location>
          L’ESPACE ETNAH GODEOK (LIGHTROOM SEOUL)
            <br />
            103, ARISU-RO 61-GIL, seoul, south korea
          </Location>
          <SiteLink
              href={"https://goo.gl/maps/Z18YzXvZAEcjXuYGA"}
              target="_blank"
              rel="noreferrer"
            >
          <VisitButton>Google Map</VisitButton>
          </SiteLink>
        </VisitItmeEl>
        <VisitItmeEl>
          <VisitImg src={"/images/visit_3.png"} />
          <Title ref={title3Ref} $isShow={isTitle3Show}>SHUTTLE BUS ROUTEs</Title>
          <Explain>
            Our shuttle buses, strategically routed to and from Seoul City’s
            major September festivities, double as standalone art experiences.
            Enjoy phenomenal works of art as you traverse through Seoul on your
            way to and from Crypto Art Seoul.
          </Explain>
          <SiteLink
              href={"https://cryptoartseoul.com/public/CAS2023_shuttle_bus_information_for_website_0901.pdf"}
              target="_blank"
              rel="noreferrer"
            >
          <VisitButton>View Shuttle Bus Time Table</VisitButton>
          </SiteLink>
          <SuttleInfoEl>
            <SuttleInfoBox>
              <Location>SHUTTLE STOP 1 : COEX / UNCOMMON GALLERY</Location>
              <SuttleMap href={"https://goo.gl/maps/ZeoqzVGN7KrHASkr5"}
                  target="_blank"
                  rel="noreferrer">Google Map</SuttleMap>
            </SuttleInfoBox>
            <SuttleInfoBox>
              <Location>SHUTTLE STOP 2  :  VISTA WALKERHILL</Location>
              <SuttleMap href={"https://goo.gl/maps/piLbSvxEioAYEt4SA"}
                  target="_blank"
                  rel="noreferrer">Google Map</SuttleMap>
            </SuttleInfoBox>
            <SuttleInfoBox>
              <Location>SHUTTLE STOP 3  :  GODEOK STATION</Location>
              <SuttleMap href={"https://goo.gl/maps/Zv41K8fo2Fa2q9kT7"}
                  target="_blank"
                  rel="noreferrer">Google Map</SuttleMap>
            </SuttleInfoBox>
          </SuttleInfoEl>
        </VisitItmeEl>
        <VisitItmeEl>
          <VisitImg src={"/images/visit_2.png"} />
          <Title ref={title2Ref} $isShow={isTitle2Show}>PLAN YOUR STAY</Title>
          <Explain>
            Guests of Crypto Art Seoul can book rooms at Vista Walker Hill at a
            discounted rate during Sep 3 - Sep 8.
          </Explain>
          <Location>
            Mountain (Achasan) View: KRW 220,000 / night
            <br />
            Han River (Hangang) View: KRW 250,000 / night
          </Location>
          <SiteLink
              href={"https://bit.ly/3PxDoYg "}
              target="_blank"
              rel="noreferrer"
            >
          <VisitButton>BOOK A ROOM</VisitButton>
          </SiteLink>
        </VisitItmeEl>
        
      </VisitPartEl>
    </>
  );
}

export default VisitPartMobile;
