import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const AboutusPartEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top:20px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 24px;
  }
`;

const Title = styled.span`
  padding-top:120px;
  color: #fff;
  font-family: Parabole;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  text-transform: uppercase;
  transition: 1s;
  clip-path:${(props)=>(props.$isShow ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)")};
  transform:${(props)=>(props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 40px;
    line-height: 48px; /* 120% */
  }
`;

const ExplainEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 24px;
  }
`;

const ExplainWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Explain = styled.span`
  width: 1064px;
  color: #fff;
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    font-size: 24px;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.72px;
  }
`;

const HighLight = styled.span`
  color: #3767ff;
  font-family: Parabole;
`;

const AboutButton = styled.button`
  display: flex;
  height: 48px;
  padding: 15px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2000px;
  border: 1px solid #fff;
  background: none;

  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1.12px;
  text-transform: uppercase;

  cursor: pointer;

  z-index:1;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 12px 16px;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.96px;
  }
`;

function AboutusPart({ setShowMenifesto }) {
  const [isTitleShow, setIsTitleShow] = useState(false);
  const [titleRef, titleInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if(titleInView){
    console.log("title show");
    setIsTitleShow(true);
  }
  }, [titleInView]);
  return (
    <>
      <AboutusPartEl>
      <a id="aboutus"></a>
        <Title ref={titleRef} $isShow={isTitleShow}>about us</Title>
        <ExplainWrapper>
          <ExplainEl>
            <Explain>
              CRYPTO ART SEOUL 2023 is brought to you by{" "}
              <HighLight>DoTPlanner</HighLight>,{" "}
              <HighLight>Aeon Studio</HighLight>, and{" "}
              <HighLight>Etnah COMPANY</HighLight>, with the shared mission of
              celebrating the spirit of innovation brought upon us by the world
              of crypto art.
            </Explain>
            <AboutButton
              onClick={(e) => {
                setShowMenifesto(true, e);
              }}
            >
              MANIFESTO
            </AboutButton>
          </ExplainEl>
        </ExplainWrapper>
      </AboutusPartEl>
    </>
  );
}

export default AboutusPart;
