import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { ScreenSizes } from '../../../styles/Themes';

const OrganizersPartEl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 72px;
  margin-top: 120px;
  @media (max-width: ${ScreenSizes.mobile}) {
    margin-top: 80px;
    flex-direction: column;
    gap: 24px;
  }
`;

const Title = styled.span`
  width: 360px;
  color: #fff;
  font-family: Parabole;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  text-transform: uppercase;
  transition: 1s;
  clip-path:${(props)=>(props.$isShow ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)")};
  transform:${(props)=>(props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    text-align: left;
    font-size: 40px;
    line-height: 48px; /* 120% */
    text-transform: uppercase;
  }
`;

const CompanyListEl = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 8px;
  }
`;

const CompanyBox = styled.div`
  width: 272px;
  height: 272px;
  border-radius: 16px;
  background: #141414;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 109px;
    height: 109px;
    border-radius: 16px;
  }
`;

const CompanyImgPC = styled.img`
  display: inline-block;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const CompanyImgMobile = styled.img`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
  }
`;

function OrganizersPart() {
  const [isTitleShow, setIsTitleShow] = useState(false);
  const [titleRef, titleInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if(titleInView){
    console.log("title show");
    setIsTitleShow(true);
  }
  }, [titleInView]);
  return (
    <OrganizersPartEl>
      <Title ref={titleRef} $isShow={isTitleShow}>Organizers</Title>
      <CompanyListEl>
        <a
          href={"https://twitter.com/aeonstudioverse"}
          target="_blank"
          rel="noreferrer"
        >
          <CompanyBox>
            <CompanyImgPC width={"151px"} src={"/logos/AeonStudio.png"} />
            <CompanyImgMobile width={"62.5px"} src={"/logos/AeonStudio.png"} />
          </CompanyBox>
        </a>
        <a
          href={"https://www.etnah.com"}
          target="_blank"
          rel="noreferrer"
        >
          <CompanyBox>
            <CompanyImgPC width={"139px"} src={"/logos/etnah.png"} />
            <CompanyImgMobile width={"67.3px"} src={"/logos/etnah.png"} />
          </CompanyBox>
        </a>
      </CompanyListEl>
    </OrganizersPartEl>
  );
}

export default OrganizersPart;
