import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const AfternoonPartEl = styled.div`
  padding: 120px 0px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 80px 0px 25px 0px;
  }
`;

const TopEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    gap: 40px;
  }
`;

const Title = styled.span`
  color: #fff;
  font-family: Parabole;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  text-transform: uppercase;
  transition: 1s;
  clip-path: ${(props) =>
    props.$isShow
      ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
      : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"};
  transform: ${(props) => (props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 40px;
    line-height: 48px; /* 120% */
  }
`;

const TopExplain = styled.span`
  color: #fff;
  text-align: right;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const TopExplainMobile = styled.span`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    color: #fff;
    text-align: right;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 1.12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 80px;
  }
`;

const LinkButton = styled.img`
  width: 40px;
  height: auto;
  cursor: pointer;
`;

const ConferenceListEl = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ConferenceItem = styled.div`
  display: flex;
  height: 112px;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  @media (max-width: ${ScreenSizes.mobile}) {
    height: auto;
    gap: 16px;
    padding: 16px 0px;
    align-items: flex-start;
  }
`;

const ItemNumber = styled.span`
  width: 216px;
  color: #fff;
  font-family: Parabole;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 112.5% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 24px;

    font-size: 14px;
    line-height: 16px; /* 114.286% */
  }
`;

const ItemExplainEl = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    gap: 8px;
  }
`;

const ItemTitle = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    font-size: 14px;
    line-height: 16px; /* 114.286% */
  }
`;

const ItemTag = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  text-align: left;
  width: 150px;
  @media (max-width: ${ScreenSizes.mobile}) {
    opacity: 0.64;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
  }
`;

const InterpretationText = styled.span`
  color: rgba(255, 255, 255, 0.64);
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  position: absolute;
  top: -33px;
  left: 184px;
  @media (max-width: ${ScreenSizes.mobile}) {
    left: 0px;
    font-size: 11px;
  }
`;

function AfternoonPart() {
  const [isTitleShow, setIsTitleShow] = useState(false);
  const [titleRef, titleInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (titleInView) {
      setIsTitleShow(true);
    }
  }, [titleInView]);
  return (
    <>
      <AfternoonPartEl>
        <TopEl>
          <Title ref={titleRef} $isShow={isTitleShow}>
            Afternoon
            <br />
            conference
          </Title>
          <TopExplain>
            VIEW FULL CONFERENCE SCHEDULE
            <a
              href={
                "https://scrawny-mail-2b3.notion.site/Conference-Full-Schedule-37642ea2c6b747949336eba79e70014f?pvs=4"
              }
              target="_blank"
              rel="noreferrer"
            >
              <LinkButton src={"/icons/pdf_button.svg"} />
            </a>
          </TopExplain>
        </TopEl>
        <ConferenceListEl>
          <InterpretationText>
            *English-Korean simlutaneous interpretation will be provided.
          </InterpretationText>
          <ConferenceItem>
            <ItemNumber>01</ItemNumber>
            <ItemExplainEl>
              <ItemTitle>Make art exist in the world</ItemTitle>
              <ItemTag>#Awareness</ItemTag>
            </ItemExplainEl>
          </ConferenceItem>
          <ConferenceItem>
            <ItemNumber>02</ItemNumber>
            <ItemExplainEl>
              <ItemTitle>Recognize the beacons of the world, and let them shine free</ItemTitle>
              <ItemTag>#Manifestation</ItemTag>
            </ItemExplainEl>
          </ConferenceItem>
          <ConferenceItem>
            <ItemNumber>03</ItemNumber>
            <ItemExplainEl>
              <ItemTitle>Heal the world’s ailments through art</ItemTitle>
              <ItemTag>#Integrity</ItemTag>
            </ItemExplainEl>
          </ConferenceItem>
          <ConferenceItem>
            <ItemNumber>04</ItemNumber>
            <ItemExplainEl>
              <ItemTitle>Bring the lights together to brighten the world</ItemTitle>
              <ItemTag>#Harmony</ItemTag>
            </ItemExplainEl>
          </ConferenceItem>
        </ConferenceListEl>
      </AfternoonPartEl>
      <TopExplainMobile>
        VIEW FULL CONFERENCE SCHEDULE
        <a
          href={
            "https://scrawny-mail-2b3.notion.site/Conference-Full-Schedule-37642ea2c6b747949336eba79e70014f?pvs=4"
          }
          target="_blank"
          rel="noreferrer"
        >
          <LinkButton src={"/icons/pdf_button.svg"} />
        </a>
      </TopExplainMobile>
    </>
  );
}

export default AfternoonPart;
