import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { Colors, ScreenSizes } from "../../styles/Themes";

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index:4;
`;

const MenifestoEl = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #000;
  //transform: translate(50%,50%);
  z-index: 6;
  overflow-y: hidden;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${Colors.gr07}; /* 스크롤바 색상 */
    border-radius: 3px; /* 스크롤바 둥근 테두리 */
  }
  &::-webkit-scrollbar-track {
    background: ${Colors.gr05}; /*스크롤바 뒷 배경 색상*/
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    height: ${(props) => props.$vh * 100 + "px"};
    min-height: ${(props) => props.$vh * 100 + "px"};
    width: ${(props) => props.$vw * 100 + "px"};
    min-width: ${(props) => props.$vw * 100 + "px"};
  }
`;

const MenifestoWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 400vw;
  overflow-y: hidden;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    height: 0px;
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    overflow-y: hidden;
    width: ${(props) => props.$vw * 100 + "px"};
    overflow-x: scroll;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  right: 80px;
  top: 40px;
  width: 40px;
  height: 40px;
  z-index: 8;
  cursor:pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    right: 16px;
    width: 32px;
    height: 32px;
  }
`;

const BottomBarEl = styled.div`
  width: 100%;
  height: 41px;

  padding: 0px 80px;
  box-sizing: border-box;
  position: absolute;
  bottom: 30px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 0px 16px;
    height: 0px;
    bottom: 30px;
  }
`;

const BottemBarWrapperEl = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgb(255, 255, 255);
  position: relative;
  z-index: 2;
`;

const BottemProgressEl = styled.div`
  width: ${(props) => props.$width};
  height: 1px;
  background-color: #3767ff;
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 6;
`;

const BottomBarText = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  opacity: ${(props) => (props.$current ? "1" : "0.32")};
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const Ellipse1 = styled.img`
  position: absolute;
  top: calc(100vh * -0.9);
  left: calc(100vh * -0.8);
  width: calc(100vh * 2);
  height: auto;
`;

const TextC = styled.img`
  position: absolute;
  height: calc(100vh * 1.1);
  left: 100vw;
  transform: translate(-100%, 0);
  top: 50px;
  opacity: 0.8;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: calc(100vh * 0.6);
    left: 100px;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

const FirstTextEl = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 127px;
  left: 80px;
  @media (max-width: ${ScreenSizes.mobile}) {
    bottom: 137px;
    left: 16px;
  }
`;

const MainTextOutline = styled.span`
  font-family: Parabole;
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: 96px; /* 100% */
  text-transform: uppercase;
  color: #000;
  text-shadow: -1px 0px white, 0px 1px white, 1px 0px white, 0px -1px white;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: ${(props) => props.$vh * 5 + "px"};
    line-height: 50px; /* 100% */
  }
`;

const MainTextFilled = styled.span`
  font-family: Parabole;
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: 96px; /* 100% */
  text-transform: uppercase;
  color: #fff;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: ${(props) => props.$vh * 5 + "px"};
    line-height: 50px; /* 100% */
  }
`;

const FirstPageArrows = styled.img`
  height: 96px;
  position: absolute;
  bottom: 127px;
  left: calc(100vw);
  transform: translate(-110%, 0);
  @media (max-width: ${ScreenSizes.mobile}) {
    height: ${(props) => props.$vh * 5 + "px"};
    bottom: 73px;
    left: ${(props) => props.$vw * 55 + "px"};
  }
`;

const Ellipse2 = styled.img`
  position: absolute;
  top: calc(100vh * -0.4);
  left: calc(100vh * 0.4);
  width: calc(100vh * 3);
  opacity: 0.8;
  height: auto;
`;

const FirstPageImg = styled.img`
  position: absolute;
  left: 1160px;
  height: calc(100vh * 0.44);
  bottom: 50%;
  transform: translate(0, 30%);
  left: calc(100vw - 300px);
  z-index: 5;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 257px;
    left: calc(100vw - 150px);
    transform: translate(0, 25%);
  }
`;

const SecondPageTextEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 524px;
  left: calc(100vw * 1.5);
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: ${(props) => props.$vw * 95 + "px"};
    left: ${(props) => props.$vw * 250 + "px"};
  }
`;

const SecondPageText = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
`;

const TextA = styled.img`
  position: absolute;
  height: calc(100vh * 1.1);
  left: calc(100vw * 1.65);
  top: calc(100vh * -0.4);
  transform: translate(-50%, 0);
  opacity: 0.8;
  @media (max-width: ${ScreenSizes.mobile}) {
    top: -50px;
    height: calc(100vh * 0.6);
    left: calc(100vw * 2.5);
    transform: translate(-30%, 0);
  }
`;

const SecondThirdTextEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 644px;
  left: calc(100vw * 2);
  transform: translate(-50%, 50%);
  position: absolute;
  @media (max-width: ${ScreenSizes.mobile}) {
    left: ${(props) => props.$vw * 400 + "px"};
    top: ${(props) => props.$vh * 15 + "px"};
    width: ${(props) => props.$vw * 95 + "px"};
    gap: 24px;
    transform: translate(-50%, 0%);
  }
`;

const SecondThirdTitle = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 24px;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.72px;
  }
`;

const SecondThirdTextList = styled.ul`
  gap: 8px;
  padding-left: 10px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 4px;
  }
`;

const SecondThirdTextLine = styled.li`
  color: #fff;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  list-style-type: square;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; /* 150% */
  }
`;

const Ellipse3 = styled.img`
  position: absolute;
  top: calc(100vh * -0.1);
  left: calc(100vw * 2);
  width: calc(100vh * 3);
  transform: translate(-50%, -50%);
  opacity: 0.8;
  height: auto;
`;

const TextS = styled.img`
  position: absolute;
  height: calc(100vh * 1.1);
  left: calc(100vw * 2.25);
  top: calc(100vh * 0.4);
  transform: translate(-50%, 0);
  opacity: 0.8;
  @media (max-width: ${ScreenSizes.mobile}) {
    left: calc(100vw * 5);
  }
`;

const ThirdTextEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 644px;
  left: calc(100vw * 2.6);
  top: calc(100vh * 0.5);
  transform: translate(-50%, -50%);
  position: absolute;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: ${(props) => props.$vw * 95 + "px"};
    left: ${(props) => props.$vw * 550 + "px"};
    gap: 24px;
  }
`;

const ThirdTitle = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 24px;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.72px;
  }
`;

const ThirdTextList = styled.ul`
  gap: 8px;
  padding-left: 10px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 4px;
  }
`;

const ThirdTextLine = styled.li`
  color: #fff;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  list-style-type: square;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    line-height: 21px; /* 150% */
  }
`;

const Ellipse4 = styled.img`
  position: absolute;
  top: calc(100vh * 1);
  left: calc(100vw * 3);
  width: calc(100vh * 3);
  transform: translate(-50%, -50%);
  opacity: 0.8;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    left: ${(props) => props.$vw * 700 + "px"};
    top: calc(100vh * 0);
  }
`;

const ForthTextEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 644px;
  left: calc(100vw * 3.25);
  top: calc(100vh * 0.5);
  transform: translate(-50%, -50%);
  position: absolute;
  @media (max-width: ${ScreenSizes.mobile}) {
    left: ${(props) => props.$vw * 650 + "px"};
    transform: translate(0, -50%);
    gap: 24px;
    width: ${(props) => props.$vw * 95 + "px"};
  }
`;

const ForthTitle = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 24px;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.72px;
  }
`;

const ForthTextList = styled.ul`
  gap: 8px;
  padding-left: 10px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 4px;
  }
`;

const ForthTextLine = styled.li`
  color: #fff;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  list-style-type: square;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    line-height: 21px; /* 150% */
  }
`;

const ForthImageEl = styled.div`
  display: flex;
  gap: 16px;
  position: absolute;
  left: calc(100vw * 3.55);
  top: calc(100vh * 0.5);
  transform: translate(0, -50%);
  height: calc(100vh * 0.48);
  @media (max-width: ${ScreenSizes.mobile}) {
    left: ${(props) => props.$vw * 800 + "px"};
    transform: translate(0, -50%);
    padding-right: 50px;
  }
`;

const ForthImage1 = styled.img`
  height: 80%;
  vertical-align: top;
`;

const ForthImage2 = styled.img`
  height: 80%;
  padding-top: 20%;
  vertical-align: bottom;
`;

const Ellipse5 = styled.img`
  position: absolute;
  top: calc(100vh * 1);
  left: calc(100vw * 4);
  width: calc(100vh * 3);
  transform: translate(-50%, -50%);
  opacity: 0.8;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

function Menifesto({ setShowMenifesto }) {
  const containerRef = useRef(null);
  const innerRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPercent, setCurrentPercent] = useState("5%");
  const [vh, setVh] = useState(0);
  const [vw, setVw] = useState(0);
  useEffect(() => {
    const container = containerRef.current;
    const vh = window.innerHeight * 0.01;
    const vw = window.innerWidth * 0.01;
    setVh(vh);
    setVw(vw);
    const handleScroll = (e) => {
      const deltaY = e.deltaY; // Get the horizontal scroll value (positive for right, negative for left)
      const scrollSpeed = 3; // Adjust the scrolling speed as desired
      // Scroll the carousel horizontally
      const isDown = deltaY > 0 ? 1 : -1;
      container.scrollTo({
        left: container.scrollLeft + deltaY * scrollSpeed,
        //left: container.scrollLeft + container.offsetWidth * isDown,
        //behavior: "smooth", // Enable smooth scrolling
      });

      const scrollSize = container.scrollWidth - container.clientWidth;
      const percent = container.scrollLeft / scrollSize;
      if (percent < 0.25) {
        setCurrentPage(0);
      } else if (0.25 <= percent && percent < 0.5) {
        setCurrentPage(1);
      } else if (0.5 <= percent && percent < 0.75) {
        setCurrentPage(2);
      } else {
        setCurrentPage(3);
      }
      const visualPercent = (container.scrollLeft / scrollSize) * 100 + 5;
      setCurrentPercent((visualPercent < 100 ? visualPercent : 100) + "%");
    };

    // Add the mouse wheel event listener
    container.addEventListener("wheel", handleScroll);

    const inner = innerRef.current;

    //console.log("percent",percent);
    if (window.innerWidth < 1000) {
      const mobilePercent = setInterval(() => {
        const scrollSize = inner.scrollWidth - inner.clientWidth;
        const visualPercent = (inner.scrollLeft / scrollSize) * 100 + 5;
        setCurrentPercent((visualPercent < 100 ? visualPercent : 100) + "%");
      }, 100);
      // Clean up the event listener on unmount
    }
    return () => {
      container.removeEventListener("wheel", handleScroll);
      //clearInterval(mobilePercent);
    };
  }, []);
  function customScrollTo(page) {
    const container = containerRef.current;
    const scrollUnit = container.scrollWidth / 4;

    container.scrollTo({
      left: scrollUnit * page,
      behavior: "smooth", // Enable smooth scrolling
    });

    const visualPercent = 25 * page + 5;
    setCurrentPage(page);
    setCurrentPercent((visualPercent < 100 ? visualPercent : 100) + "%");
  }
  return (
    <Wrapper>
      <MenifestoEl ref={containerRef} $vh={vh} $vw={vw}>
        <CloseButton
          src={"/icons/menifesto_close.svg"}
          onClick={(e) => {
            setShowMenifesto(false, e);
          }}
        />
        <MenifestoWrapper ref={innerRef} $vw={vw}>
          <Ellipse1 src={"/images/Ellipse_1.png"} />
          <TextC src={"images/text_c.svg"} />
          <FirstTextEl>
            <MainTextOutline $vh={vh}>CRYPTO ART,</MainTextOutline>
            <MainTextOutline $vh={vh}>AS DEFINED BY</MainTextOutline>
            <MainTextFilled $vh={vh}>crypto art seoul</MainTextFilled>
          </FirstTextEl>
          <FirstPageArrows
            $vh={vh}
            $vw={vw}
            src={"/images/menifesto_arrows.svg"}
          />
          <FirstPageImg src={"/images/menifesto_img_1.png"} />
          <Ellipse2 src={"/images/Ellipse_1.png"} />
          <SecondPageTextEl $vw={vw}>
            <SecondPageText>
              Artistic endeavors that utilize blockchain technology to challenge
              conventional wisdom and break away from existing systems and
              regulations.
            </SecondPageText>
            <SecondPageText>
              More than just a genre or trend; it is a concept that encompasses
              not only the product, but also the process.
            </SecondPageText>
          </SecondPageTextEl>
          <TextA src={"images/text_a.svg"} />
          <SecondThirdTextEl $vh={vh} $vw={vw}>
            <SecondThirdTitle>
              The Purpose of
              <br />
              Crypto Art Seoul
            </SecondThirdTitle>
            <SecondThirdTextList>
              <SecondThirdTextLine>
                To challenge prejudicial dialogues surrounding crypto art.
              </SecondThirdTextLine>
              <SecondThirdTextLine>
                To embrace crypto art as the culture that it is, celebrating its
                spirit and its radical nature in its entirety.
              </SecondThirdTextLine>
              <SecondThirdTextLine>
                To challenge onlookers to dive deep past the realm of
                opportunism and speculation into its artistic core.
              </SecondThirdTextLine>
              <SecondThirdTextLine>
                To uphold the essence of crypto art by highlighting the artistic
                intent rather than the technology alone.
              </SecondThirdTextLine>
              <SecondThirdTextLine>
                To spread awareness of the open medium that is crypto art.
              </SecondThirdTextLine>
              <SecondThirdTextLine>
                To continuously serve the community as a bridge between the
                realms of art and technology, and help form their intersection
                that is crypto art.
              </SecondThirdTextLine>
              <SecondThirdTextLine>
                To nurture a healthy ecosystem for crypto art and culture to
                thrive through Crypto Art Seoul.
              </SecondThirdTextLine>
            </SecondThirdTextList>
          </SecondThirdTextEl>
          <Ellipse3 src={"/images/Ellipse_1.png"} />
          <TextS src={"images/text_s.svg"} />
          <ThirdTextEl $vh={vh} $vw={vw}>
            <ThirdTitle>
              The Vision of
              <br />
              Crypto Art Seoul
            </ThirdTitle>
            <ThirdTextList>
              <ThirdTextLine>
                We will connect people, fields, cities, and spirits through art.
              </ThirdTextLine>
              <ThirdTextLine>
                We will become a creative platform upon which paradigm shifts
                can occur in the most organic and healthy of ways possible, and
                lead crypto art into its next chapter.
              </ThirdTextLine>
            </ThirdTextList>
          </ThirdTextEl>
          <Ellipse4 src={"/images/Ellipse_1.png"} />
          <ForthTextEl $vh={vh} $vw={vw}>
            <ForthTitle>
              Manifesto of
              <br />
              Crypto Art Seoul
            </ForthTitle>
            <ForthTextList>
              <ForthTextLine>
                Crypto Art Seoul focuses on the nature of human beings through
                art.
              </ForthTextLine>
              <ForthTextLine>
                Crypto Art Seoul acknowledges differences and embraces
                everyone's voice without discrimination.
              </ForthTextLine>
              <ForthTextLine>
                Crypto Art Seoul promotes the expansion of art through
                technology.
              </ForthTextLine>
              <ForthTextLine>
                Crypto Art Seoul encourages the spirit of fearless challenges,
                made possible through hope, imagination, resistance, and
                dissidence.
              </ForthTextLine>
              <ForthTextLine>
                Crypto Art Seoul acts, rather than talking.
              </ForthTextLine>
              <ForthTextLine>
                Crypto Art Seoul breaks down boundaries between subjects and
                objects to integrate all into the culture.
              </ForthTextLine>
            </ForthTextList>
          </ForthTextEl>
          <ForthImageEl $vh={vh} $vw={vw}>
            <ForthImage1 src={"/images/forth_1.png"} />
            <ForthImage2 src={"/images/forth_2.png"} />
          </ForthImageEl>
          <Ellipse5 src={"/images/Ellipse_1.png"} $vh={vh} $vw={vw} />
        </MenifestoWrapper>
        <BottomBarEl>
          <BottemBarWrapperEl>
            <BottomBarText
              $current={currentPage === 0}
              onClick={(e) => {
                customScrollTo(0);
              }}
            >
              Crypto Art Seoul
            </BottomBarText>
            <BottomBarText
              $current={currentPage === 1}
              onClick={(e) => {
                customScrollTo(1);
              }}
            >
              The Purpose
            </BottomBarText>
            <BottomBarText
              $current={currentPage === 2}
              onClick={(e) => {
                customScrollTo(2);
              }}
            >
              The Vision
            </BottomBarText>
            <BottomBarText
              $current={currentPage === 3}
              onClick={(e) => {
                customScrollTo(3);
              }}
            >
              Manifesto
            </BottomBarText>
            <BottomBarText></BottomBarText>
            <BottemProgressEl $width={currentPercent}></BottemProgressEl>
          </BottemBarWrapperEl>
        </BottomBarEl>
      </MenifestoEl>
    </Wrapper>
  );
}

export default Menifesto;
