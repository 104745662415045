import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SpeakersSlideItem from "./SpeakersSlideItem";
import { ScreenSizes } from "../../../styles/Themes";

const SlideContainer = styled.div`
  margin-top: 64px;
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  align-items: center;
  overflow-x: hidden;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 95vw;
    margin-top: 16px;
    overflow-y: hidden;
  }
`;

const InnerCarouselEl = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.$itemCount / 2}, 1fr);
  gap: 8px;
  transition: 1s ease;
  transform: translate(${(props) => props.$sliderCount}, 0);
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const LeftArrow = styled.span`
  position: absolute;
  left: 0;
  color: #fff;
  font-size: 40px;
  z-index: 2;
  transition: 0.5s;
  opacity: ${(props) => (props.$isHover ? "1" : "0")};
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const RigthArrow = styled.span`
  position: absolute;
  right: 0;
  color: #fff;
  font-size: 40px;
  z-index: 2;
  transition: 0.5s;
  opacity: ${(props) => (props.$isHover ? "1" : "0")};
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const MobileSliderWrapperEl = styled.div`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
    overflow: scroll;
  }
`;

const MobileInnerCarouselEl = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.$itemCount}, 1fr);
  gap: 8px;
`;

const SpeakersList = [
  { name: "6529", explain: "6529 Collection", imgName: "6529.png" },
  {
    name: "Alex Aravantinos",
    explain: "Curator",
    imgName: "AlexAravantinos.png",
  },
  { name: "Beatriz Ramos", explain: "DADA", imgName: "BeatrizRamos.png" },
  { name: "Clara Peh", explain: "Curator", imgName: "ClaraPeh.png" },
  { name: "DarrenSRS", explain: "6529 Capital & Open Metaverse", imgName: "DarrenSRS.png" },
  { name: "Eli Scheinman", explain: "PROOF", imgName: "EliScheinman.png" },
  { name: "Grida", explain: "NFC / IHAM Gallery", imgName: "Grida.png" },
  { name: "Hyein Jeon", explain: "etnah company / HanDAO", imgName: "HyeinJeon.png" },
  { name: "Jin Lee", explain: "CC.Magnet", imgName: "JinLee.png" },
  { name: "Joy Jo", explain: "Crypto Artist", imgName: "JoyJo.png" },
  { name: "Lev AAN", explain: "Art Critic", imgName: "LevAAN.png" },
  { name: "LuYang", explain: "Artist", imgName: "LuYang.png" },
  { name: "Michelle Kim", explain: "DoTPlanner", imgName: "MichelleKim.png" },
  { name: "Minji Kim", explain: "Freelance MC", imgName: "MinjiKim.png" },
  { name: "Nakta", explain: "Crypto Artist", imgName: "Nakta.png" },
  {
    name: "Pauline Faieff",
    explain: "Crypto Artist",
    imgName: "PaulineFaieff.png",
  },
  { name: "Regina Kim", explain: "Crypto Artist", imgName: "ReginaKim.png" },
  { name: "Sam Gellman", explain: "PROOF", imgName: "SamGellman.png" },
  { name: "Serena Tabacchi", explain: "MOCDA", imgName: "SerenaTabacchi.png" },
  { name: "ShinyTiger", explain: "Aeon Studio / Crypto Artist", imgName: "ShinyTiger.png" },
  { name: "WhaleShark", explain: "Collector", imgName: "WhaleShark.png" },
  { name: "YuYu", explain: "Crypto Artist", imgName: "YuYu.png" },
  { name: "Aleksandra Art", explain: "Joyn.xyz", imgName: "AleksandraArt.png" },
  { name: "Andy Ku", explain: "Altava Group / etnah company", imgName: "AndyKu.png" },
  { name: "Claire Silver", explain: "Collaborative AI Artist", imgName: "ClaireSilver.png" },
  { name: "Colborn Bell", explain: "MoCA / zeroone", imgName: "ColbornBell.png" },
  {
    name: "Edward Zipco",
    explain: "Superchief Gallery NFT",
    imgName: "EdwardZipco.png",
  },
  { name: "Gordon Berger", explain: "Crypto Artist", imgName: "GordonBerger.png" },
  { name: "Hackatao", explain: "Crypto Artist", imgName: "Hackatao.png" },
  { name: "Jaeseok Kim", explain: "Gallery Hyundai", imgName: "JaeseokKim.png" },
  { name: "John Karp", explain: "NFC", imgName: "JohnKarp.png" },
  { name: "Judy Mam", explain: "DADA", imgName: "JudyMam.png" },
  { name: "Lucia Kang", explain: "Aeon Studio / HanDAO", imgName: "LuciaKang.png" },
  {
    name: "Martin Lukas Ostachowski",
    explain: "Crypto Art Historian",
    imgName: "MartinLukasOstachowski.png",
  },
  { name: "Micol Ap", explain: "Vertical", imgName: "MicolAp.png" },
  { name: "MrMisang", explain: "Crypto Artist", imgName: "MrMisang.png" },
  { name: "Pak", explain: "The Nothing", imgName: "Pak.png" },
  { name: "Polygon1993", explain: "Crypto Artist", imgName: "Polygon1993.png" },
  { name: "Ryan Gander", explain: "Artist", imgName: "RyanGander.png" },
  { name: "Sebastien Borget", explain: "The Sandbox", imgName: "SebastienBorget.png" },
  { name: "Seungsoon Park", explain: "Mix.Audio", imgName: "SeungsoonPark.png" },
  { name: "Vandalo Ruins", explain: "SEWER", imgName: "VandaloRuins.png" },
  { name: "Yoyojin", explain: "Crypto Artist", imgName: "Yoyojin.png" },
  { name: "TBA", explain: "TBA", imgName: "TBA.png" },
];

const MobileSpeakersList = [
  { name: "6529", explain: "6529 Collection", imgName: "6529.png" },
  { name: "Aleksandra Art", explain: "Joyn.xyz", imgName: "AleksandraArt.png" },
  {
    name: "Alex Aravantinos",
    explain: "Curator",
    imgName: "AlexAravantinos.png",
  },
  { name: "Andy Ku", explain: "Altava Group / etnah company", imgName: "AndyKu.png" },
  { name: "Beatriz Ramos", explain: "DADA", imgName: "BeatrizRamos.png" },
  { name: "Claire Silver", explain: "Collaborative AI Artist", imgName: "ClaireSilver.png" },
  { name: "Clara Peh", explain: "Curator", imgName: "ClaraPeh.png" },
  { name: "Colborn Bell", explain: "MoCA / zeroone", imgName: "ColbornBell.png" },
  { name: "DarrenSRS", explain: "6529 Capital & Open Metaverse", imgName: "DarrenSRS.png" },
  {
    name: "Edward Zipco",
    explain: "Superchief Gallery NFT",
    imgName: "EdwardZipco.png",
  },
  { name: "Eli Scheinman", explain: "PROOF", imgName: "EliScheinman.png" },
  { name: "Gordon Berger", explain: "Crypto Artist", imgName: "GordonBerger.png" },
  { name: "Grida", explain: "NFC / IHAM Gallery", imgName: "Grida.png" },
  { name: "Hackatao", explain: "Crypto Artist", imgName: "Hackatao.png" },
  { name: "Hyein Jeon", explain: "etnah company / HanDAO", imgName: "HyeinJeon.png" },
  { name: "Jaeseok Kim", explain: "Gallery Hyundai", imgName: "JaeseokKim.png" },
  { name: "Jin Lee", explain: "CC.Magnet", imgName: "JinLee.png" },
  { name: "John Karp", explain: "NFC", imgName: "JohnKarp.png" },
  { name: "Joy Jo", explain: "Crypto Artist", imgName: "JoyJo.png" },
  { name: "Judy Mam", explain: "DADA", imgName: "JudyMam.png" },
  { name: "Lev AAN", explain: "Art Critic", imgName: "LevAAN.png" },
  { name: "Lucia Kang", explain: "Aeon Studio / HanDAO", imgName: "LuciaKang.png" },
  { name: "LuYang", explain: "Artist", imgName: "LuYang.png" },
  {
    name: "Martin Lukas Ostachowski",
    explain: "Crypto Art Historian",
    imgName: "MartinLukasOstachowski.png",
  },
  { name: "Michelle Kim", explain: "DoTPlanner", imgName: "MichelleKim.png" },
  { name: "Micol Ap", explain: "Vertical", imgName: "MicolAp.png" },
  { name: "Minji Kim", explain: "Freelance MC", imgName: "MinjiKim.png" },
  { name: "MrMisang", explain: "Crypto Artist", imgName: "MrMisang.png" },
  { name: "Nakta", explain: "Crypto Artist", imgName: "Nakta.png" },
  { name: "Pak", explain: "The Nothing", imgName: "Pak.png" },
  {
    name: "Pauline Faieff",
    explain: "Crypto Artist",
    imgName: "PaulineFaieff.png",
  },
  { name: "Polygon1993", explain: "Crypto Artist", imgName: "Polygon1993.png" },
  { name: "Regina Kim", explain: "Crypto Artist", imgName: "ReginaKim.png" },
  { name: "Ryan Gander", explain: "Artist", imgName: "RyanGander.png" },
  { name: "Sam Gellman", explain: "PROOF", imgName: "SamGellman.png" },
  { name: "Sebastien Borget", explain: "The Sandbox", imgName: "SebastienBorget.png" },
  { name: "Serena Tabacchi", explain: "MOCDA", imgName: "SerenaTabacchi.png" },
  { name: "Seungsoon Park", explain: "Mix.Audio", imgName: "SeungsoonPark.png" },
  { name: "ShinyTiger", explain: "Aeon Studio / Crypto Artist", imgName: "ShinyTiger.png" },
  { name: "Vandalo Ruins", explain: "SEWER", imgName: "VandaloRuins.png" },
  { name: "WhaleShark", explain: "Collector", imgName: "WhaleShark.png" },
  { name: "Yoyojin", explain: "Crypto Artist", imgName: "Yoyojin.png" },
  { name: "YuYu", explain: "Crypto Artist", imgName: "YuYu.png" },
];

function SpeakersSlider() {
  const [isHover, setIsHover] = useState(false);
  const [sliderCount, setSliderCount] = useState(0);
  const [maxSlideCount, setMaxSlideCount] = useState(0);
  const wrapperRef = useRef(null);
  const innerRef = useRef(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const inner = innerRef.current;
    setMaxSlideCount(Math.ceil((inner.offsetWidth - wrapper.offsetWidth) / 316));

    function handleResize() {
      setMaxSlideCount(Math.ceil((inner.offsetWidth - wrapper.offsetWidth) / 316));
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function leftHandler() {
    if (0 < sliderCount) {
      setSliderCount(sliderCount - 1);
    }
  }

  function rightHandler() {
    if (sliderCount < maxSlideCount) {
      setSliderCount(sliderCount + 1);
    }
  }

  return (
    <>
      <SlideContainer
        ref={wrapperRef}
        onMouseEnter={(e) => {
          setIsHover(true);
        }}
        onMouseLeave={(e) => {
          setIsHover(false);
        }}
      >
        <LeftArrow onClick={leftHandler} $isHover={isHover}>
          {"<"}
        </LeftArrow>
        <InnerCarouselEl
          ref={innerRef}
          $itemCount={SpeakersList.length}
          $sliderCount={-316 * sliderCount + "px"}
        >
          {SpeakersList.map((speaker, index) => (
            <SpeakersSlideItem key={index} speaker={speaker}></SpeakersSlideItem>
          ))}
        </InnerCarouselEl>
        <RigthArrow onClick={rightHandler} $isHover={isHover}>
          {">"}
        </RigthArrow>
        <MobileSliderWrapperEl>
          <MobileInnerCarouselEl $itemCount={SpeakersList.length}>
            {MobileSpeakersList.map((speaker, index) => (
              <SpeakersSlideItem key={index} speaker={speaker}></SpeakersSlideItem>
            ))}
          </MobileInnerCarouselEl>
        </MobileSliderWrapperEl>
      </SlideContainer>
    </>
  );
}

export default SpeakersSlider;
