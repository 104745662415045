import { useEffect } from 'react';
import styled from "styled-components";
import { metaMask } from "../../lib/metamaskConnectors";
import { walletConnectV2 } from "../../lib/walletconnectConnectors";
import { ScreenSizes } from "../../styles/Themes";
import { useWeb3React } from "@web3-react/core";

const RightBackGround = styled.div`
  width: 50%;
  height: 100%;
  background-color: #f4f4f4;
  position: absolute;
  top: 0px;
  right: 0px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const TopWrapperEl = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction:column;
    align-items:center;
  }
`;

const TopLeftEl = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 38px;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 107px;
    justify-content: flex-end;
    padding-bottom:16px;
  }
`;

const TopLeftTitle = styled.span`
  color: #000;
  text-align: center;
  font-family: Parabole;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 112.5% */
  text-transform: uppercase;
`;

const TicketImg = styled.img`
  width: 384px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display:none;
  }
`;

const TopRightEl = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    background:#F4F4F4;
    padding:83px 16px 64px 16px;
    justify-content: flex-start;
    box-sizing: border-box;
    gap: 8px;
  }
`;

const ProgressEl = styled.div`
  position: absolute;
  left: 48px;
  top: 48px;
  gap: 10px;
  display: flex;
  @media (max-width: ${ScreenSizes.mobile}) {
    left: 16px;
    top: 32px;
  }
`;

const ProgressBar1 = styled.div`
  width: 32px;
  height: 3px;
  border-radius: 100px;
  background: #3767ff;
`;

const ProgressBar2 = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 100px;
  background: #000;
`;

const ProgressBar3 = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 100px;
  background: #000;
`;

const ProgressTitle = styled.span`
  position: absolute;
  left: 48px;
  top: 89px;
  color: #000;
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    position:static;
    width:100%;
    text-align: left;
  }
`;

const ConnectWalletEl = styled.div`
  width: 616px;
  position: absolute;
  top: 113px;
  left: 19px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    position:static;
  }
`;

const WalletEl = styled.div`
  width: 100%;
  padding: 56px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 32px 0px;
  }
`;

const WalletImg = styled.img`
  height: 24px;
`;

const ConnectButton = styled.span`
  color: #3767ff;
  text-align: right;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
  cursor: pointer;
`;

function TicketConnectWallet({setStep}) {
  const { account, connector } = useWeb3React();
  async function connectWalletHandler(type) {
    metaMask.resetState();
    walletConnectV2.deactivate();
    if (type === "metamask") {
      await metaMask.activate(1).then().catch((error)=>console.log(error));
    } else if (type === "walletconnect") {
      await walletConnectV2.activate(1).then().catch((error)=>console.log(error));
    }
  }
  useEffect(()=>{
    if(account !== undefined && connector !== undefined){
      setStep(1);
    }
  },[account, connector]);

  return (
    <>
      <RightBackGround />
      <TopWrapperEl>
        <TopLeftEl>
          <TopLeftTitle>NFT Ticketing</TopLeftTitle>
          <TicketImg src={"/images/ticket.png"} />
        </TopLeftEl>
        <TopRightEl>
          <ProgressEl>
            <ProgressBar1 />
            <ProgressBar2 />
            <ProgressBar3 />
          </ProgressEl>
          <ProgressTitle>Connect your wallet</ProgressTitle>
          <ConnectWalletEl>
            <WalletEl>
              <WalletImg src={"/images/full-walletconnect-logo.svg"} />
              <ConnectButton
                onClick={(e) => {
                  connectWalletHandler("walletconnect", e);
                }}
              >
                connect
              </ConnectButton>
            </WalletEl>
            <WalletEl>
              <WalletImg src={"/images/metamask.svg"} />
              <ConnectButton
                onClick={(e) => {
                  connectWalletHandler("metamask", e);
                }}
              >
                connect
              </ConnectButton>
            </WalletEl>
          </ConnectWalletEl>
          {/* <TicketTellus />
          <TicketCheckout /> */}
        </TopRightEl>
        {/* <TicketPurchaseResult /> */}
      </TopWrapperEl>
    </>
  );
}
export default TicketConnectWallet;
