import styled, { keyframes } from "styled-components";
import { ScreenSizes } from "../../styles/Themes";

const MarqueeEl = styled.div`
  position: absolute;
  width: 500px;
  transform: rotate(-90deg);
  transform-origin: 10% 100%;
  position: sticky;
  left: 0px;
  top: 600px;
  transition: 1s;
  opacity: ${(props) => (props.$show ? "1" : "0")};
  z-index: 40;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const MarqueeContainer = styled.div`
  position: relative;
  width: auto;
  height: 22px;
  overflow: hidden;
`;

const marqueeAnimation = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
`;

const MarqueeTrack = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimation} 16s linear infinite;
`;

const MarqueeContent = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const HardForkingText = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  opacity: 0.5;
`;

const CASText = styled.span`
  color: #fff;
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
`;

function MainMarquee({ showComponents,showMenifesto }) {
  return (
    <MarqueeEl $show={showComponents && !showMenifesto}>
      <MarqueeContainer>
        <MarqueeTrack>
          <MarqueeContent>
                <HardForkingText>
                  HARDFORKING HUMANITY
                </HardForkingText>
                <CASText>crypto art seoul</CASText>
                <HardForkingText>
                  HARDFORKING HUMANITY
                </HardForkingText>
                <CASText>crypto art seoul</CASText>
                <HardForkingText>
                  HARDFORKING HUMANITY
                </HardForkingText>
                <CASText>crypto art seoul</CASText>
                <HardForkingText>
                  HARDFORKING HUMANITY
                </HardForkingText>
                <CASText>crypto art seoul</CASText>
                <HardForkingText>
                  HARDFORKING HUMANITY
                </HardForkingText>
                <CASText>crypto art seoul</CASText>
                <HardForkingText>
                  HARDFORKING HUMANITY
                </HardForkingText>
                <CASText>crypto art seoul</CASText>
                <HardForkingText>
                  HARDFORKING HUMANITY
                </HardForkingText>
                <CASText>crypto art seoul</CASText>
                <HardForkingText>
                  HARDFORKING HUMANITY
                </HardForkingText>
                <CASText>crypto art seoul</CASText>
                <HardForkingText>
                  HARDFORKING HUMANITY
                </HardForkingText>
                <CASText>crypto art seoul</CASText>
                <HardForkingText>
                  HARDFORKING HUMANITY
                </HardForkingText>
                <CASText>crypto art seoul</CASText>
          </MarqueeContent>
        </MarqueeTrack>
      </MarqueeContainer>
    </MarqueeEl>
  );
}
export default MainMarquee;
