import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const SchedulePartEl = styled.div`
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const Title = styled.span`
  color: var(--white, #fff);
  font-family: Parabole;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  text-transform: uppercase;
  transition: 1s;
  clip-path: ${(props) =>
    props.$isShow
      ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
      : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"};
  transform: ${(props) => (props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
`;

const ScheduleTable = styled.table`
  width: 100%;
  border-spacing: 16px 2px;
`;

const ScheduleThTime = styled.th`
  width: 108px;
  text-align: left;
  color: #fff;
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  height: 41px;
`;

const ScheduleTh = styled.th`
  width: 200px;
  text-align: left;
  color: #fff;
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  height: 41px;
`;

const ScheduleThNarrow = styled.th`
  width: 154px;
  text-align: left;
  color: #fff;
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  height: 41px;
`;

const ScheduleTdTime = styled.td`
  height: 27px;
  vertical-align: baseline;
  color: #fff;
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  border-radius: 8px;
`;

const ScheduleTd = styled.td`
  height: 27px;
  vertical-align: baseline;
  border-radius: 8px;
`;

const ScheduleTdBlue = styled.td`
  height: 27px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid rgba(55, 103, 255, 0.8);
`;

const ScheduleTdPurple = styled.td`
  height: 27px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid rgba(151, 71, 255, 0.8);
`;

const ScheduleTdGray = styled.td`
  height: 27px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
`;

const BlueContentsEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const WhiteText = styled.span`
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
`;

const GrayText = styled.span`
  width: 176px;
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  opacity: 0.8;
`;

const GrayTextWide = styled.span`
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  opacity: 0.8;
`;

const GrayContentsEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
`;

const GrayTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EllipseImg = styled.img`
  position: absolute;
  width: 3000px;
  bottom: 0px;
  right: 0px;
  transform: translate(60%, 50%);
  z-index: -1;
`;

function SchedulePart2() {
  const [isTitleShow, setIsTitleShow] = useState(false);
  const [titleRef, titleInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (titleInView) {
      console.log("title show");
      setIsTitleShow(true);
    }
  }, [titleInView]);
  return (
    <SchedulePartEl>
      
      <Title ref={titleRef} $isShow={isTitleShow}>
        FULL
        <br />
        SCHEDULE
      </Title>
      <ScheduleTable>
        <thead>
          <tr>
            <ScheduleThTime>TIME</ScheduleThTime>
            <ScheduleTh>SEP 5(TUE)</ScheduleTh>
            <ScheduleTh></ScheduleTh>
            <ScheduleTh>SEP 6(WED)</ScheduleTh>
            <ScheduleTh></ScheduleTh>
            <ScheduleThNarrow>SEP 7(THUR)</ScheduleThNarrow>
            <ScheduleThNarrow></ScheduleThNarrow>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ScheduleTdTime>10:00</ScheduleTdTime>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTdBlue colSpan="2" rowSpan="4">
              <BlueContentsEl>
                <WhiteText>
                  SEOUL ART TOUR <br />
                  COURSE 1 : <br />
                  <br />
                  Théâtre des Lumieres
                </WhiteText>
              </BlueContentsEl>
            </ScheduleTdBlue>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>11:00</ScheduleTdTime>
            <ScheduleTdBlue rowSpan="19">
              <BlueContentsEl>
                <WhiteText>
                  Mobility Experience
                  <br />
                  (Shuttle Bus Exhibition)
                </WhiteText>
                <WhiteText>Special Exhibition</WhiteText>
                <WhiteText>Open-call Exhibition</WhiteText>
                <WhiteText>Partner Exhibition</WhiteText>
              </BlueContentsEl>
            </ScheduleTdBlue>
            <ScheduleTd></ScheduleTd>
            <ScheduleTdBlue rowSpan="16">
              <BlueContentsEl>
                <WhiteText>
                  Mobility Experience
                  <br />
                  (Shuttle Bus Exhibition)
                </WhiteText>
                <WhiteText>Special Exhibition</WhiteText>
                <WhiteText>Open-call Exhibition</WhiteText>
                <WhiteText>Partner Exhibition</WhiteText>
              </BlueContentsEl>
            </ScheduleTdBlue>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>12:00</ScheduleTdTime>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>13:00</ScheduleTdTime>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTdBlue colSpan="2" rowSpan="10">
              <BlueContentsEl>
                <WhiteText>
                  SEOUL ART TOUR
                  <br />
                  COURSE 2 :<br />
                  <br />
                  Uncommon Gallery
                  <br />
                  <br />
                  National Museum of Modern and Contemporary Art
                  <br />
                  <br />
                  Art Center Nabi
                </WhiteText>
              </BlueContentsEl>
            </ScheduleTdBlue>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>14:00</ScheduleTdTime>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTdGray>
              <GrayTextBox>
                <WhiteText>Opening Ceremony</WhiteText>
              </GrayTextBox>
            </ScheduleTdGray>
            <ScheduleTdGray>
              <GrayTextBox>
                <WhiteText>Opening Ceremony</WhiteText>
              </GrayTextBox>
            </ScheduleTdGray>
          </tr>
          <tr>
            <ScheduleTdTime>15:00</ScheduleTdTime>
            <ScheduleTdGray rowSpan="3">
              <GrayTextBox>
                <WhiteText>Section 1: #Awareness</WhiteText>
                <GrayText>Make art exist in the world</GrayText>
              </GrayTextBox>
            </ScheduleTdGray>
            <ScheduleTdGray rowSpan="3">
              <GrayTextBox>
                <WhiteText>Session 3: #Integrity</WhiteText>
                <GrayText>Heal the ailments of the world with art</GrayText>
              </GrayTextBox>
            </ScheduleTdGray>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>16:00</ScheduleTdTime>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTdGray>
              {" "}
              <GrayTextBox>
                <WhiteText>Break</WhiteText>
              </GrayTextBox>
            </ScheduleTdGray>
            <ScheduleTdGray>
              <GrayTextBox>
                <WhiteText>Break</WhiteText>
              </GrayTextBox>
            </ScheduleTdGray>
          </tr>
          <tr>
            <ScheduleTdTime>17:00</ScheduleTdTime>
            <ScheduleTdGray rowSpan="7">
              <GrayTextBox>
                <WhiteText>Session 2: #Manifestation</WhiteText>
                <GrayText>Recognize the beacons of the world, and let them shine free</GrayText>
              </GrayTextBox>
              <GrayTextBox style={{ "marginTop": "40px" }}>
                <WhiteText>Networking Party</WhiteText>
              </GrayTextBox>
            </ScheduleTdGray>
            <ScheduleTdGray rowSpan="4">
              <GrayTextBox>
                <WhiteText>Session 4: #Harmony</WhiteText>
                <GrayText>Bring lights together to brighten our world</GrayText>
              </GrayTextBox>
            </ScheduleTdGray>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>18:00</ScheduleTdTime>
            <ScheduleTdBlue colSpan="2" rowSpan="6">
              <BlueContentsEl>
                <WhiteText>
                  SEOUL ART TOUR
                  <br />
                   COURSE 3 :<br />
                  <br />
                  National Museum of Korea
                  <br /><br />
                  ARA Art Center
                </WhiteText>
              </BlueContentsEl>
            </ScheduleTdBlue>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>19:00</ScheduleTdTime>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>20:00</ScheduleTdTime>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTdBlue>
              <BlueContentsEl>
                <WhiteText>Art Performance</WhiteText>
              </BlueContentsEl>
            </ScheduleTdBlue>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <ScheduleTdTime>21:00</ScheduleTdTime>
            <ScheduleTdPurple colSpan="2" rowSpan="4">
              <BlueContentsEl>
                <WhiteText>
                  Hahoetal Masquerade - Networking Party
                  <br />
                  (Co-hosted by The Sandbox)
                </WhiteText>
              </BlueContentsEl>
            </ScheduleTdPurple>
            <ScheduleTdPurple colSpan="2">
              {" "}
              <BlueContentsEl>
                <WhiteText>Art Performance</WhiteText>
              </BlueContentsEl>
            </ScheduleTdPurple>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTdPurple colSpan="2" rowSpan="11">
              <GrayTextBox>
                <WhiteText>
                  Seoul Art Night + Special Performance
                  <br />
                  (Co-hosted by Gallery Hyundai)
                </WhiteText>
                <GrayText style={{ width: "100%" }}>*Frieze Seoul Gallery Night</GrayText>
              </GrayTextBox>
            </ScheduleTdPurple>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>22:00</ScheduleTdTime>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>23:00</ScheduleTdTime>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>24:00</ScheduleTdTime>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>01:00</ScheduleTdTime>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTdTime>02:00</ScheduleTdTime>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
          <tr>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
            <ScheduleTd></ScheduleTd>
          </tr>
        </tbody>
      </ScheduleTable>
      <EllipseImg src={"/images/Ellipse_1.png"} />
    </SchedulePartEl>
  );
}

export default SchedulePart2;
