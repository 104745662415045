import styled from "styled-components";
import { ScreenSizes,Colors } from "../styles/Themes";

const ModalBackground = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 60;
  box-sizing: border-box;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 16px;
  }
`;

const ModalEl = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 80px 216px;
  border-radius: 16px;
  background: #3767ff;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 64px 24px;
    max-width: 342px;
    border-radius: 8px;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    top: 24px;
    right: 24px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 56px;
  font-family: Parabole;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
  text-transform: uppercase;

  margin-bottom: 63px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 24px;
  }
`;

const TextEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height:500px;
  overflow-y:scroll;
  padding-right:100px;
  &::-webkit-scrollbar {
    width:6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #FFF; /* 스크롤바 색상 */
    border-radius: 5px; /* 스크롤바 둥근 테두리 */
  }
  &::-webkit-scrollbar-track {
    background: rgba(255,255,255,0.2); /*스크롤바 뒷 배경 색상*/
    border-radius: 5px; /* 스크롤바 둥근 테두리 */
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    padding-right:8px;
  }
`;

const NormalTextBox = styled.div`
  font-size: 18px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    line-height: normal;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > ul {
    font-size: 18px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    list-style-type: square;
    gap: 8px;
    margin: 0px;
    @media (max-width: ${ScreenSizes.mobile}) {
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

const TextTitle = styled.span`
  font-size: 18px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    line-height: normal;
  }
`;

const NormalText = styled.span`
  font-size: 18px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    line-height: normal;
  }
`;

const ScrollTextBox = styled.div`
display:flex;
`;

function PrivacyModal({ setIsPrivacyModalOpen }) {
  return (
    <ModalBackground
      onClick={(e) => {
        setIsPrivacyModalOpen(false, e);
      }}
    >
      <ModalEl onClick={(e) => e.stopPropagation()}>
        <CloseButton
          src={"/icons/Close.svg"}
          onClick={(e) => {
            setIsPrivacyModalOpen(false, e);
          }}
        />
        <Title>Privacy Policy</Title>
        <TextEl>
          <NormalTextBox>
            This website is operated by the organization (hereafter referred to
            as the “Organization”) of the Crypto Art Seoul (hereafter referred
            to as the “Event”).
            <br />
            <br />
            This Privacy Policy (henceforth the "Policy") explains the way of
            treatment of the information which is provided or collected in the
            websites on which this Policy is posted. In addition, the Policy
            also explains the information which is provided or collected in the
            course of using the applications of the Event which exist in the
            websites or platforms of other companies.
            <br />
            <br />
            Through this Policy, the Organization acknowledges the importance of
            properly handling the users’ personal information, and informs them
            of the purpose and method of the Organization's use of the personal
            information provided by the users and the measures taken by the
            Organization for protection of said personal information.
            <br />
            <br />
            This Policy will be effective on June 26, 2023 and, in case of
            modification thereof, the Organization will make public notice of it
            through posting it on the bulletin board of the Organization’s
            website or individual notice through sending mails, fax or e-mails.
          </NormalTextBox>
          <TextBox>
            <TextTitle>
              1. Information to be collected and method of collection
            </TextTitle>
            <ul>
              <li>
                Personal information items to be collected:
                <br />
                Personal information item(s) to be collected by the Organization
                are as follows:
                <br />- First name, last name, nickname, phone number(s), email,
                nationality, and affiliation directly provided by the user.
              </li>
              <li>
                Method of collection:
                <br />
                The Organization collects the information of users in the
                following way(s):
                <br />- Webpage (Crypto Art Seoul) and email
              </li>
            </ul>
          </TextBox>
          <TextBox>
            <TextTitle>2. Use of collected information</TextTitle>
            <NormalText>
              The Organization uses the collected information of users for the
              following purposes:
            </NormalText>
            <ul>
              <li>
                To notify users of event details, such as opening dates for
                tickets
              </li>
              <li>
                For marketing and promotional purposes; for example, the
                Organization may send emails or text messages  to the Event’s
                registrants and prospective newsletter recipients regarding
                information on the Event that we think may be of interest to
                them including the corresponding year and the next year
              </li>
              <li>
                Notifying users of the function of Organization’s sites,
                applications, or matters on policy change 
              </li>
              <li>
                Use of information with prior consent of the users (for example,
                utilization of marketing advertisement) 
              </li>
              <li>
                To prepare the name badge and other materials related to the
                Event and midnight party
              </li>
              <li>
                To process any administrative or financial (related to the
                payment of your registration fee) matters that may arise in
                preparing for the Event
              </li>
              <li>
                To report on the result of the Event to any supporting
                organizations such as the local convention bureau, some parts of
                your information may be used (for example, your name,
                affiliation, e-mail address, etc.).
              </li>
            </ul>
            <NormalText>
              The Organization agrees that it will obtain consent from the
              users, if the Organization desires to use the information other
              than those expressly stated in this Policy.
            </NormalText>
          </TextBox>
          <TextBox>
            <TextTitle>3. Sharing collected information</TextTitle>
            <NormalText>
              The Organization will not share your personal information with a
              3rd party, except in following cases: 
            </NormalText>
            <ul>
              <li>
                When the Organization’s affiliates, partners, and service
                providers carry out services such as bill payment, name badge
                and other onsite material printing, and dispute resolution
                (including disputes on payment and delivery) for and on behalf
                of the Organization
              </li>
              <li>If required to be disclosed by the laws and regulations</li>
              <li>
                If required to be disclosed by the investigative agencies for
                detecting crimes in accordance with the procedure and method as
                prescribed in the laws and regulations
              </li>
            </ul>
          </TextBox>
          <TextBox>
            <TextTitle>4. Users' right to access and option</TextTitle>
            <NormalText>
              The users or their legal representatives, as main agents of the
              information, may exercise the following options regarding the
              collection, use and sharing of personal information by the
              Organization:
            </NormalText>
            <ul>
              <li>Exercise right to access personal information;</li>
              <li>Make corrections or deletion;</li>
              <li>
                Make temporary suspension of treatment of personal information;
                or
              </li>
              <li>Request the withdrawal of their consent provided before </li>
            </ul>
            <NormalText>
              If, in order to exercise the above options, you, as a user,
              contact the Organization by emailing the responsible department
              (or person in charge of management of personal information), the
              Organization will take measures without delay: Provided that the
              Organization may reject the request of you only to the extent that
              there exists either proper cause as prescribed in the laws or
              equivalent cause.
            </NormalText>
          </TextBox>
          <TextBox>
            <TextTitle>5. Security</TextTitle>
            <NormalText>
              The Organization regards maintaining the security of your personal
              information storage very important. The Organization constructs
              the following security measures to protect the users' personal
              information from any unauthorized access, release, use or
              modification:
            </NormalText>
            <ul>
              <li>
                Store users’ personal information in the zone the external
                access to which is controlled so as to prevent leakage or damage
                by hacking or computer virus
              </li>
            </ul>
          </TextBox>
          <TextBox>
            <TextTitle>6. Modification of Privacy Protection Policy</TextTitle>
            <NormalText>
              The Organization has the right to amend or modify this Policy from
              time to time and, in such a case, the Organization will make a
              public notice of it through a section on its website (or through
              individual notice such as written document, fax or email) and
              obtain consent from the users if required by relevant laws.
            </NormalText>
          </TextBox>
          <TextBox>
            <TextTitle>
              7. Department of the responsible for the users’ personal
              information
            </TextTitle>
            <NormalText>
              The Organization designates the following department and person in
              charge of personal information in order to protect personal
              information of customers and deal with complaints from customers:
              <br />
              <br />
              Department: SECRETARIAT OF CRYPTO ART SEOUL
              <br />
              Address: #918, 100 Cheonggyecheon-ro, Jung-gu, Seoul, Korea
              (04542)
              <br />
              Tel.: 070-4263-5518
              <br />
              E-mail: info@cryptoartseoul.com
            </NormalText>
          </TextBox>
          <TextBox>
            <TextTitle>
              This Policy has been last updated on: June 26, 2023
            </TextTitle>
          </TextBox>
        </TextEl>
      </ModalEl>
    </ModalBackground>
  );
}
export default PrivacyModal;
