import styled  from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const WelcomePartEl = styled.div`
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  padding: 0px 80px;
  box-sizing: border-box;
  margin-top: 120px;
  gap: 64px;
  transition: 1s;
  transition-delay: 0.5s;
  opacity: ${(props) => (props.$show ? "1" : "0")};
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 40px;
    padding: 0px 16px;
  }
`;

const TopText = styled.span`
  width: 100%;
  color: #fff;
  font-family: Parabole;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  text-transform: uppercase;
  transition: 1s;
  clip-path:${(props)=>(props.$isShow ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)")};
  transform:${(props)=>(props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 40px;
    line-height: 48px; /* 120% */
  }
`;
const ImgBox = styled.div`
  width: 100%;
  @media (max-width: ${ScreenSizes.mobile}) {
    max-width: 343px;
    height: 192px;
    overflow: hidden;
  }
`;
const WelcomeImg = styled.img`
  width: 100%;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: auto;
    height: 100%;
  }
`;

const BottomTextEl = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    gap: 51px;
  }
`;

const BottemTextBox1 = styled.span`
  width: 414px;
  color: #fff;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 343px;
    font-size: 18px;
    line-height: 27px;
  }
`;

const BottemTextBox2 = styled.span`
  width: 416px;
  color: #fff;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 343px;
    font-size: 18px;
    line-height: 27px;
  }
`;

const HighLightText = styled.span`
  color: #3767ff;
  font-family: Parabole;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 18px;
    line-height: 27px;
  }
`;

const MobileBr = styled.br`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
  }
`;

function WelcomePart({ showComponents }) {
  const [isTitleShow, setIsTitleShow] = useState(false);
  const [titleRef, titleInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if(titleInView){
    console.log("title show");
    setIsTitleShow(true);
  }
  }, [titleInView]);
  return (
    <WelcomePartEl $show={showComponents}>
      <TopText ref={titleRef} $isShow={isTitleShow}>
      {/* <TopText> */}
        Welcome to
        <MobileBr /> humanity's
        <MobileBr /> hardfork
      </TopText>
      <ImgBox>
        <WelcomeImg src={"/images/welcome.png"} />
      </ImgBox>
      <BottomTextEl>
        <BottemTextBox1>
          <HighLightText>Crypto Art Seoul</HighLightText> is Asia's premier
          crypto art event that challenges the art world to perceive the bigger
          picture.
        </BottemTextBox1>
        <BottemTextBox2>
          Crypto art’s ever-growing momentum, paired with Korea’s{" "}
          <HighLightText>Hongik spirit</HighLightText> - that all of us should
          serve for the good of all mankind -, will resonate in a fiery
          crescendo during this year’s celebration of art and innovation.
        </BottemTextBox2>
      </BottomTextEl>
    </WelcomePartEl>
  );
}

export default WelcomePart;
