import styled from "styled-components";
import AboutusPart from "../components/aboutus/AboutusPart";
import AfternoonPart from "../components/agenda/AfternoonPart";
import ArtistsPart from "../components/agenda/artists/ArtistsPart";
import ExhibitionPart from "../components/agenda/ExhibitionPart";
import FeaturesPart from "../components/agenda/FeaturesPart";
import Header from "../components/header/Header";
import HostsPart from "../components/aboutus/companies/HostsPart";
import InvolvedPart from "../components/involved/InvolvedPart";
import SchedulePart from "../components/agenda/SchedulePart";
import SpeakersPart from "../components/agenda/speakers/SpeakersPart";
import TicketsPart from "../components/tickets/TicketsPart";
import TopPart from "../components/top/TopPart";
import VisitPart from "../components/visit/VisitPart";
import WelcomePart from "../components/tickets/WelcomePart";
import { ScreenSizes } from "../styles/Themes";
import OrganizersPart from "../components/aboutus/companies/OrganizersPart";
import PartnersPart from "../components/aboutus/companies/PartnersPart";
import Footer from "../components/footer/Footer";
import ArtistSlider from "../components/agenda/artists/ArtistSlider";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Menifesto from "../components/manifesto/Menifesto";
import { componentActions } from "../store/componentSlice";
import TicketBaseModal from "../modal/ticket/TicketBaseModal";
import VisitPartMobile from "../components/visit/VisitPartMobile";
import SchedulePartMobile from "../components/agenda/SchedulePartMobile";
import { useInView } from "react-intersection-observer";
import MainMarquee from "../components/marquee/MainMarquee";
import PrivacyModal from "../modal/PrivacyModal";
import SpeakersSlider from '../components/agenda/speakers/SpeakersSlider';
import FoundingPartnersPart from '../components/aboutus/companies/FoundingPartnersPart';

const MainEl = styled.div`
  width: 100%;
  display: ${(props) => (props.$display ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  padding: 0px;
  color: #ffffff;
  position: relative;
  overflow: hidden;
`;

const MainWrapperEl = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0px 80px;
  box-sizing: border-box;
  transition: 1s;
  transition-delay: 0.5s;
  opacity: ${(props) => (props.$show ? "1" : "0")};
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 0px 16px;
  }
`;

const EllipseArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const EllipseImg = styled.img`
  position: absolute;
  width: 2000px;
  opacity: 0.6;
  top: 0px;
  left: 0px;
  transition: 0s ease-in-out;
  transform: translate(-50%, 30%);
  z-index:-1;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const HideArea = styled.div`
  height: ${(props) => props.$height + "px"};
  width: 100%;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index:0;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

function MainPage({ mintCount, showMenifesto, setShowMenifesto }) {
  const [showComponents, setShowComponents] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const isFirst = useSelector((state) => state.component.isFirst);
  const scrollY = useSelector((state) => state.component.scrollY);
  const dispatch = useDispatch();
  const [currentSection, setCurrentSection] = useState(0);
  const [headerScrollY, setHeaderScrollY] = useState(0);
  const [hideEllipseAreaHight, setHideEllipseAreaHight] = useState(0);
  const ellipseRef = useRef(null);
  const ellipseAreaRef = useRef(null);

  const [agendaRef, agendaInView] = useInView({
    threshold: 0.1,
  });
  const [visitRef, visitInView] = useInView({
    threshold: 0.1,
  });
  const [getInvolvedRef, getInvolvedInView] = useInView({
    threshold: 0,
  });
  const [aboutUsRef, aboutUsInView] = useInView({
    threshold: 0.1,
  });

  function showMenifestoHandler() {
    if (!showMenifesto) {
      dispatch(componentActions.setScrollY(window.scrollY));
    } else {
      console.log("scroll");
      dispatch(componentActions.setIsScrollSmooth(false));
      window.location.href = "#involved";
      setTimeout(() => {
        dispatch(componentActions.setIsScrollSmooth(true));
      }, 500);
    }
    setShowMenifesto(!showMenifesto);
  }
  useEffect(() => {
    if (isFirst) {
      //console.log("first");
      window.scrollTo(0, 0);
      dispatch(componentActions.setIsFirst(false));
    } else {
      //console.log("here");
      window.scrollTo(0, scrollY);
    }
    function onScroll(e) {
      setHeaderScrollY(window.scrollY);
      moveEllipse(e);
    }

    window.addEventListener("scroll", (e) => {
      onScroll(e);
    });

    function moveEllipse(e) {
      const area = ellipseAreaRef.current;
      const areaRect = area.getBoundingClientRect();
      const relativeTop = areaRect.top;
      const scrolledTopLength = window.pageYOffset;
      const areaTop = scrolledTopLength + relativeTop - 110;
      setHideEllipseAreaHight(areaTop);

      const mouseX = e.pageX;
      const mouseY = e.pageY;

      //console.log("move : ", mouseX + " " + mouseY);
      ellipseRef.current.style.left = mouseX + "px";
      ellipseRef.current.style.top = mouseY - areaTop + "px";
      //ellipseRef.current.style.top = mouseY+"px";
    }

    window.addEventListener("mousemove", (e) => {
      moveEllipse(e);
    });

    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("mousemove", moveEllipse);
    };
  }, []);

  useEffect(() => {
    if (agendaInView) {
      setCurrentSection(1);
    } else if (visitInView) {
      setCurrentSection(2);
    } else if (getInvolvedInView) {
      setCurrentSection(3);
    } else if (aboutUsInView) {
      setCurrentSection(4);
    } else {
      setCurrentSection(0);
    }
  }, [agendaInView, visitInView, getInvolvedInView, aboutUsInView]);

  return (
    <>
      {showMenifesto && <Menifesto setShowMenifesto={showMenifestoHandler} />}
      {isTicketModalOpen && (
        <TicketBaseModal
          setIsTicketModalOpen={setIsTicketModalOpen}
          setIsPrivacyModalOpen={setIsPrivacyModalOpen}
          mintCount={mintCount}
        />
      )}
      {isPrivacyModalOpen && (
        <PrivacyModal setIsPrivacyModalOpen={setIsPrivacyModalOpen} />
      )}
      <MainMarquee
        showComponents={showComponents}
        showMenifesto={showMenifesto}
      />
      <Header
        showComponents={showComponents}
        currentSection={currentSection}
        headerScrollY={headerScrollY}
      />

      <MainEl $display={!showMenifesto}>
        <HideArea $height={hideEllipseAreaHight} />
        <TopPart setShowComponents={setShowComponents} />
        <TicketsPart
          showComponents={showComponents}
          setIsTicketModalOpen={setIsTicketModalOpen}
          setIsPrivacyModalOpen={setIsPrivacyModalOpen}
          mintCount={mintCount}
        />
        <EllipseArea ref={ellipseAreaRef} $height={hideEllipseAreaHight}>
          <EllipseImg src={"/images/Ellipse_1.png"} ref={ellipseRef} />
          <WelcomePart showComponents={showComponents} />
          <MainWrapperEl $show={showComponents}>
            <a id="agenda"></a>
            <div ref={agendaRef}>
              <SchedulePart />
              <SchedulePartMobile />
              <ExhibitionPart />
              <ArtistsPart />
              <ArtistSlider />
              <AfternoonPart />
              <SpeakersPart />
              <SpeakersSlider />
              <FeaturesPart />
            </div>
            <div ref={visitRef}>
              <VisitPart />
              <VisitPartMobile />
              <a id="involved"></a>
            </div>
            <div ref={getInvolvedRef}>
              <InvolvedPart />
            </div>
            <div ref={aboutUsRef}>
              <AboutusPart setShowMenifesto={showMenifestoHandler} />
              <HostsPart />
              <OrganizersPart />
              <FoundingPartnersPart />
              <PartnersPart />
              <Footer />
            </div>
          </MainWrapperEl>
        </EllipseArea>
      </MainEl>
    </>
  );
}

export default MainPage;
