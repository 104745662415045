import styled, { keyframes } from "styled-components";
import {ScreenSizes} from "../../styles/Themes";

const LoadingEl = styled.div`
  width: 100%;
  height: 526px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 560px;
  }
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingCircle = styled.img`
  animation: ${rotateAnimation} 2s linear infinite;
`;

function TicketLoading() {
  return (
    <LoadingEl>
      <LoadingCircle src={"/images/loading_circle.svg"} />
    </LoadingEl>
  );
}
export default TicketLoading;
