import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/storeIndex";
import { Provider } from "react-redux";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { metaMask, hooks as metamaskHooks } from "./lib/metamaskConnectors";
import { walletConnectV2, hooks as walletconnectHooks } from "./lib/walletconnectConnectors";

const connectors = [[walletConnectV2, walletconnectHooks],[metaMask, metamaskHooks]];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <Web3ReactProvider connectors={connectors}>
      <App />
    </Web3ReactProvider>
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
