import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useEffect, useState, useRef } from "react";

const TopPartEl = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 0px 80px;
  box-sizing: border-box;
  height: 905px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #ffffff;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 0px 18px;
    align-items: center;
    height: 617px;
  }
`;

const TopEl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 122px;
  transition: 1s;
  transition-delay: 0.5s;
  opacity: ${(props) => (props.$show ? "1" : "0")};
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    gap: 81px;
    width: 100%;
  }
`;

const TopRightText = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.72px;
    width: 100%;
    text-align: left;
  }
`;

const MiddleEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 133px;
  margin-bottom: 68px;
  transition: 1s;
  transition-delay: 0.5s;
  opacity: ${(props) => (props.$show ? "1" : "0")};
  @media (max-width: ${ScreenSizes.mobile}) {
    margin-top: 184px;
    margin-bottom: 30px;
  }
`;

const MiddleText = styled.span`
  color: #fff;

  /* Subhead 2 */
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    line-height: normal;
  }
`;

const MainTextEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  height: 357px;
  margin-bottom: 64px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 356px;
    gap: 8.46px;
    margin-bottom: 80px;
  }
`;

const MainTextOutline = styled.span`
  font-family: Parabole;
  font-size: ${(props) => props.$fontSize};
  font-style: normal;
  font-weight: 400;
  line-height: 73%;
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
  color: #000;
  text-shadow: -1px 0px white, 0px 1px white, 1px 0px white, 0px -1px white;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 36px;
  }
`;

const MobileBr = styled.br`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
  }
`;

//For Intro animation
const CenterText = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 1s;
  opacity: ${(props) => (props.$showCenterText ? "1" : "0")};
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    font-size: 20px;
    text-align: center;
  }
`;

function TopPart({ setShowComponents }) {
  const [showCenterText, setShowCenterText] = useState(false);
  const [firstLineText, setFirstLineText] = useState("");
  const [middleLineText, setMiddleLineText] = useState("");
  const [lastLineText, setLastLineText] = useState("");
  const [showFinal, setShowFinal] = useState(false);
  const [mainTextSize, setMainTextSize] = useState("30px");
  const textBoxRef = useRef(null);
  const firstLineTextList = [
    "C",
    "CR",
    "CRY",
    "CRYP",
    "CRYPT",
    "CRYPTO",
    "CRYPTO A",
    "CRYPTO AR",
    "CRYPTO ART",
    "CRYPTO ART S",
    "CRYPTO ART SE",
    "CRYPTO ART SEO",
    "CRYPTO ART SEOU",
    "CRYPTO ART SEOUL",
    "CRYPTO ART SEOUL",
    "CRYPTO ART SEOUL",
    "<span style='color:white; text-shadow:none'>CRYPTO</span> ART SEOUL",
    "<span style='color:white; text-shadow:none'>CRYPTO</span> ART SEOUL",
    "<span style='color:white; text-shadow:none'>CRYPTO</span> ART SEOUL",
    "<span style='color:white; text-shadow:none'>CRYPTO</span> ART SEOUL",
    "<span style='color:white; text-shadow:none'>CRYPTO ART SEOUL</span>",
    "<span style='color:white; text-shadow:none'>CRYPTO ART SEOUL</span>",
    "CRYPTO ART SEOUL",
    "<span style='color:white; text-shadow:none'>CRYPTO ART SEOUL</span>",
  ];
  const middleLineTextList = [
    "",
    "",
    "",
    "C",
    "CR",
    "CRY",
    "CRYP",
    "CRYPT",
    "CRYPTO",
    "CRYPTO A",
    "CRYPTO AR",
    "CRYPTO ART",
    "CRYPTO ART S",
    "CRYPTO ART SE",
    "CRYPTO ART SEO",
    "CRYPTO ART SEOU",
    "CRYPTO ART SEOUL",
    "CRYPTO ART SEOUL",
    "CRYPTO ART SEOUL",
    "CRYPTO <span style='color:white; text-shadow:none'>ART</span> SEOUL",
    "CRYPTO <span style='color:white; text-shadow:none'>ART</span> SEOUL",
    "CRYPTO ART SEOUL",
    "CRYPTO ART SEOUL",
    "<span style='color:white; text-shadow:none'>CRYPTO ART SEOUL</span>",
    "<span style='color:white; text-shadow:none'>CRYPTO ART SEOUL</span>",
  ];
  const lastLineTextList = [
    "",
    "",
    "<span style='black:white; text-shadow:none'>CRYPTO ART SEOU</span>L",
    "<span style='black:white; text-shadow:none'>CRYPTO ART SEO</span>UL",
    "<span style='black:white; text-shadow:none'>CRYPTO ART SE</span>OUL",
    "<span style='black:white; text-shadow:none'>CRYPTO ART S</span>EOUL",
    "<span style='black:white; text-shadow:none'>CRYPTO ART</span> SEOUL",
    "<span style='black:white; text-shadow:none'>CRYPTO AR</span>T SEOUL",
    "<span style='black:white; text-shadow:none'>CRYPTO A</span>RT SEOUL",
    "<span style='black:white; text-shadow:none'>CRYPTO </span>ART SEOUL",
    "<span style='black:white; text-shadow:none'>CRYPT</span>O ART SEOUL",
    "<span style='black:white; text-shadow:none'>CRYP</span>TO ART SEOUL",
    "<span style='black:white; text-shadow:none'>CRY</span>PTO ART SEOUL",
    "<span style='black:white; text-shadow:none'>CR</span>YPTO ART SEOUL",
    "<span style='black:white; text-shadow:none'>C</span>RYPTO ART SEOUL",
    "CRYPTO ART SEOUL",
    "CRYPTO ART SEOUL",
    "CRYPTO ART SEOUL",
    "CRYPTO ART SEOUL",
    "CRYPTO ART SEOUL",
    "CRYPTO ART <span style='color:white; text-shadow:none'>SEOUL</span>",
    "<span style='color:white; text-shadow:none'>CRYPTO ART SEOUL</span>",
    "CRYPTO ART SEOUL",
    "<span style='color:white; text-shadow:none'>CRYPTO ART SEOUL</span>",
  ];
  useEffect(() => {
    setShowCenterText(true);
    setTimeout(() => {
      setShowCenterText(false);
      setTimeout(() => {
        textBlink();
      }, 1000);
    }, 1000);
    function textBlink() {
      let textIndex = 0;
      let firstTimer = setInterval(() => {
        if (textIndex === firstLineTextList.length - 1) {
          clearInterval(firstTimer);
          setShowFinal(true);
          setShowComponents(true);
        }
        setFirstLineText(firstLineTextList[textIndex]);
        setMiddleLineText(middleLineTextList[textIndex]);
        setLastLineText(lastLineTextList[textIndex]);
        textIndex++;
      }, 50);
    }
    const boxWidth = textBoxRef.current.getBoundingClientRect().width;
    console.log("width : ", textBoxRef.current.getBoundingClientRect().width);
    setMainTextSize(boxWidth / 9.6 + "px");
    function handleResize() {
      const boxWidth = textBoxRef.current.getBoundingClientRect().width;
      //console.log("width : ", textBoxRef.current.getBoundingClientRect().width);
      setMainTextSize(boxWidth / 9.6 + "px");
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <a id="top"></a>
      <TopPartEl>
        <CenterText $showCenterText={showCenterText}>
          HARDFORKING HUMANITY
        </CenterText>
        <TopEl $show={showFinal}>
          <TopRightText>HARDFORKING HUMANITY</TopRightText>
        </TopEl>
        <MiddleEl $show={showFinal}>
          <MiddleText>
            THE WORLD OF CRYPTO ART IS <MobileBr />
            GATHERING IN SEOUL.
          </MiddleText>
        </MiddleEl>
        <MainTextEl ref={textBoxRef}>
          <MainTextOutline
            $fontSize={mainTextSize}
            dangerouslySetInnerHTML={{ __html: firstLineText }}
          ></MainTextOutline>
          <MainTextOutline
            $fontSize={mainTextSize}
            dangerouslySetInnerHTML={{ __html: middleLineText }}
          ></MainTextOutline>
          <MainTextOutline
            $fontSize={mainTextSize}
            dangerouslySetInnerHTML={{ __html: lastLineText }}
          ></MainTextOutline>
        </MainTextEl>
      </TopPartEl>
    </>
  );
}
export default TopPart;
