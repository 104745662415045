import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Web3 from "web3";
import ContractInfo from "../contracts/CAS2023.json";

const ABI = ContractInfo.abi;
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

const initialState = {
  isMinting: 0,
  tokenId: 0,
};

const web3MintSlice = createSlice({
  name: "web3MintSlice",
  initialState,
  reducers: {
    initiateMinting(state) {
      state.isMinting = 0;
      state.tokenId = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncWeb3Mint.pending, (state) => {
        console.log("now minting");
        state.isMinting = 1;
      })
      .addCase(asyncWeb3Mint.fulfilled, (state, action) => {
        console.log(action.payload);
        const txResult = action.payload;
        const hexTokenId = txResult.logs[0].topics[3];
        const resultTokenId = parseInt(hexTokenId);
        console.log("tokenId", resultTokenId);
        state.tokenId = resultTokenId;
        state.isMinting = 2;
      })
      .addCase(asyncWeb3Mint.rejected, (state, action) => {
        console.log(action.payload);
        state.isMinting = 3;
      });
  },
});

const asyncWeb3Mint = createAsyncThunk(
  "web3MintSlice/asyncWeb3Mint",
  async (params, { getState, rejectWithValue }) => {
    const { account, amount, provider } = params;
    const web3 = new Web3(provider.provider);
    const contract = new web3.eth.Contract(ABI, CONTRACT_ADDRESS);
    //console.log(provider);
    try {
      let result = await contract.methods
        .mintNFT(amount)
        .send({ from: account, value: 0.2 * 10 ** 18 * amount });
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export { asyncWeb3Mint };
export const web3MintActions = web3MintSlice.actions;
export default web3MintSlice.reducer;
