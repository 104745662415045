import { useState } from "react";
import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import { asyncSaveBeforeMint } from "../../store/serverSlice";
import { asyncWeb3Mint } from "../../store/web3MintSlice";

const RightBackGround = styled.div`
  width: 50%;
  height: 100%;
  background-color: #f4f4f4;
  position: absolute;
  top: 0px;
  right: 0px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const TopWrapperEl = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    align-items: center;
  }
`;

const TopLeftEl = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 38px;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 107px;
    justify-content: flex-end;
    padding-bottom: 16px;
  }
`;

const TicketImg = styled.img`
  width: 384px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const ConnectedWalletIcon = styled.div`
  border-radius: 8px;
  border: 1px solid #000;
  padding: 8px 12px;
  position: absolute;
  bottom: 47px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const ConnectedWalletImg = styled.img`
  height: 16px;
`;

const TopLeftTitle = styled.span`
  color: #000;
  text-align: center;
  font-family: Parabole;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 112.5% */
  text-transform: uppercase;
`;

const TopRightEl = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    background: #f4f4f4;
    padding: 59px 16px 48px 16px;
    justify-content: flex-start;
    box-sizing: border-box;
    gap: 8px;
  }
`;

const ProgressEl = styled.div`
  position: absolute;
  left: 48px;
  top: 48px;
  gap: 10px;
  display: flex;
  @media (max-width: ${ScreenSizes.mobile}) {
    left: 16px;
    top: 32px;
  }
`;

const ProgressBar1 = styled.div`
  width: 32px;
  height: 3px;
  border-radius: 100px;
  background: #3767ff;
`;

const ProgressBar2 = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 100px;
  background: #000;
`;

const ProgressBar3 = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 100px;
  background: #000;
`;

const ProgressTitle = styled.span`
  position: absolute;
  left: 48px;
  top: 89px;
  color: #000;
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    position: static;
    width: 100%;
    text-align: left;
  }
`;

const CheckoutEl = styled.div`
  width: 616px;
  position: absolute;
  top: 113px;
  left: 19px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    position: static;
  }
`;

const SelectQuantityEl = styled.div`
  width: 100%;
  height: 93px;
  padding: 16px 0px 12px 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ExplainEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ExplainTitle = styled.span`
  color: #000;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
`;

const TicketRemainEl = styled.span`
  color: rgba(0, 0, 0, 0.8);
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

const QuantitySelectEl = styled.div`
  border-radius: 8px;
  border: 1px solid #000;
  padding: 7px 8px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const QuantityButton = styled.span`
  color: #000;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  cursor: pointer;
`;

const QuantityNumber = styled.span`
  color: #000;
  width: 13px;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
`;

const TotalQuantityEl = styled.div`
  width: 100%;
  height: 93px;
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  padding-top: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TotalPriceEl = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalEthEl = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const EthImg = styled.img`
  width: 16px;
`;

const TotalEthText = styled.span`
  color: #000;
  text-align: right;
  font-family: Parabole;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: uppercase;
`;

const GasText = styled.span`
  color: var(--black, #000);
  text-align: right;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

const PurchaseButton = styled.div`
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 2000px;
  box-sizing: border-box;

  color: #fff;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.96px;
  text-transform: uppercase;

  cursor: pointer;
`;

const StepEl = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 110px;
  @media (max-width: ${ScreenSizes.mobile}) {
    margin-top: 30px;
  }
`;

const BackButton = styled.img`
  width: 32px;
  cursor: pointer;
`;

function TicketCheckout({ setStep, userName, userMail, userCountry, mintCount }) {
  const { account, connector, provider } = useWeb3React();
  const [ticketQuantity, setTicketQuantity] = useState(1);
  const dispatch = useDispatch();

  function quantityHandler(type) {
    if (type === "minus") {
      if (ticketQuantity > 1) {
        setTicketQuantity(ticketQuantity - 1);
      }
    } else if (type === "plus") {
      if(ticketQuantity < 100-mintCount){
      setTicketQuantity(ticketQuantity + 1);
    }
    }
  }

  function purchaseHandler() {
    dispatch(asyncSaveBeforeMint({ userName, userMail, userCountry, account }));
    dispatch(asyncWeb3Mint({ account, amount: ticketQuantity, provider }));
  }

  return (
    <>
      <RightBackGround />
      <TopWrapperEl>
        <TopLeftEl>
          <TopLeftTitle>NFT Ticketing</TopLeftTitle>
          <TicketImg src={"/images/ticket.png"} />
          {connector.constructor.name === "MetaMask" ? (
            <ConnectedWalletIcon>
              <ConnectedWalletImg src={"/images/metamask.svg"} />
            </ConnectedWalletIcon>
          ) : (
            <ConnectedWalletIcon>
              <ConnectedWalletImg src={"/images/full-walletconnect-logo.svg"} />
            </ConnectedWalletIcon>
          )}
        </TopLeftEl>
        <TopRightEl>
          <ProgressEl>
            <ProgressBar2 />
            <ProgressBar3 />
            <ProgressBar1 />
          </ProgressEl>
          <ProgressTitle>proceed with checkout</ProgressTitle>
          <CheckoutEl>
            <SelectQuantityEl>
              <ExplainEl>
                <ExplainTitle>Crypto Art Seoul 2023 PASS</ExplainTitle>
                <TicketRemainEl>
                  Admission for one
                  <br />
                  {100-mintCount}/100 left
                </TicketRemainEl>
              </ExplainEl>
              <QuantitySelectEl>
                <QuantityButton
                  onClick={(e) => {
                    quantityHandler("minus");
                  }}
                >
                  -
                </QuantityButton>
                <QuantityNumber>{ticketQuantity}</QuantityNumber>
                <QuantityButton
                  onClick={(e) => {
                    quantityHandler("plus");
                  }}
                >
                  +
                </QuantityButton>
              </QuantitySelectEl>
            </SelectQuantityEl>
            <TotalQuantityEl>
              <ExplainEl>
                <ExplainTitle>Total</ExplainTitle>
                <TicketRemainEl>
                  {ticketQuantity} x Crypto Art Seoul 2023 PASS
                </TicketRemainEl>
              </ExplainEl>
              <TotalPriceEl>
                <TotalEthEl>
                  <EthImg src={"/icons/eth.svg"} />
                  <TotalEthText>{(2000 * ticketQuantity) / 10000}</TotalEthText>
                </TotalEthEl>
                <GasText>+ Gas</GasText>
              </TotalPriceEl>
            </TotalQuantityEl>
            <StepEl>
              <BackButton
                src={"/icons/back.svg"}
                onClick={(e) => {
                  setStep(1);
                }}
              />
              <PurchaseButton onClick={purchaseHandler}>
                complete purchase
              </PurchaseButton>
            </StepEl>
          </CheckoutEl>
        </TopRightEl>
      </TopWrapperEl>
    </>
  );
}
export default TicketCheckout;
