import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { web3MintActions } from '../../store/web3MintSlice';
import { useDispatch } from "react-redux";

const ErrorEl = styled.div`
  width: 100%;
  height: 526px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  z-index: 15;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 560px;
  }
`;

const ErrorTitle = styled.span`
  color: var(--black, #000);
  font-family: Parabole;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    text-align: center;
  }
`;

const ErrorExplain = styled.span`
  color: var(--black, #000);
  text-align: center;

  /* Body 1 */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const BackToCheckoutButton = styled.button`
  padding: 8px 16px;
  border-radius: 2000px;
  background: var(--black, #000);
  color: var(--white, #fff);

  /* Button 2 */
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.96px;
  text-transform: uppercase;

  cursor:pointer;
`;

const MobileBr = styled.br`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
  }
`;

function TicketError() {
  const dispatch = useDispatch();
  function backHandler(){
    dispatch(web3MintActions.initiateMinting());
  }
  return (
    <ErrorEl>
      <ErrorTitle>
        whoa,
        <MobileBr /> something
        <MobileBr /> went wrong!
      </ErrorTitle>
      <ErrorExplain>
        We couldn’t get you your ticket(s).
        <br />
        Please try again.
      </ErrorExplain>
      <BackToCheckoutButton onClick={backHandler}>back to checkout</BackToCheckoutButton>
    </ErrorEl>
  );
}
export default TicketError;
