import styled from "styled-components";
import { useEffect, useState } from "react";
import TicketLoading from "./TicketLoading";
import TicketError from "./TicketError";
import TicketTellus from "./TicketTellus";
import TicketCheckout from "./TicketCheckout";
import TicketPurchaseResult from "./TicketPurchaseResult";
import TicketConnectWallet from "./TicketConnectWallet";
import { ScreenSizes } from "../../styles/Themes";
import { useSelector, useDispatch } from "react-redux";
import { web3MintActions } from "../../store/web3MintSlice";
import { metaMask } from "../../lib/metamaskConnectors";
import { walletConnectV2 } from "../../lib/walletconnectConnectors";

const TicketBackGroundEl = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
`;

const TicketBaseModalEl = styled.div`
  width: 100%;
  height: 532px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: auto;
  }
`;

const TopEl = styled.div`
  width: 100%;
  height: 467px;
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: auto;
  }
`;

const BottomEl = styled.div`
  width: 100%;
  height: 64px;
  border-top: 1px solid #000;
  display: flex;
  justify-content: center;
`;

const BottomWrapperEl = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
  box-sizing: border-box;
`;

const BottomTitle = styled.span`
  color: #000;
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
`;

const CloseButton = styled.button`
  display: flex;
  height: 32px;
  padding: 15px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2000px;
  border: 1px solid #000;
  background: none;

  color: #000;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.96px;
  text-transform: uppercase;

  cursor: pointer;
`;

function TicketBaseModal({ setIsTicketModalOpen, setIsPrivacyModalOpen, mintCount }) {
  const [step, setStep] = useState(0);
  const [userName, setUserName] = useState("");
  const [userMail, setUserMail] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const isMinting = useSelector((state) => state.mint.isMinting);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(web3MintActions.initiateMinting());
  }, []);
  useEffect(() => {
    if (isMinting === 2) {
      setStep(3);
    }
  }, [isMinting]);
  function closeModalHandler(){
    dispatch(web3MintActions.initiateMinting());
    metaMask.resetState();
    walletConnectV2.deactivate();
    setIsTicketModalOpen(false);
    setStep(0);
  }
  return (
    <TicketBackGroundEl>
      <TicketBaseModalEl>
        {isMinting === 1 && <TicketLoading />}
        {isMinting === 3 && <TicketError />}
        <TopEl>
          {step === 0 && <TicketConnectWallet setStep={setStep} />}
          {step === 1 && (
            <TicketTellus
              setIsPrivacyModalOpen={setIsPrivacyModalOpen}
              setStep={setStep}
              setUserName={setUserName}
              setUserMail={setUserMail}
              setUserCountry={setUserCountry}
            />
          )}
          {step === 2 && (
            <TicketCheckout
              setStep={setStep}
              userName={userName}
              userMail={userMail}
              userCountry={userCountry}
              mintCount={mintCount}
            />
          )}
          {step === 3 && (
            <TicketPurchaseResult
              userName={userName}
              userMail={userMail}
              userCountry={userCountry}
            />
          )}
        </TopEl>
        <BottomEl>
          <BottomWrapperEl>
            <BottomTitle>crypto art seoul</BottomTitle>
            <CloseButton
              onClick={closeModalHandler}
            >
              Close
            </CloseButton>
          </BottomWrapperEl>
        </BottomEl>
      </TicketBaseModalEl>
    </TicketBackGroundEl>
  );
}

export default TicketBaseModal;
