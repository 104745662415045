import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const InvolvedPartEl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 120px;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    padding-top: 80px;
  }
`;

const Title = styled.span`
  color: #fff;
  font-family: Parabole;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  text-transform: uppercase;
  transition: 1s;
  clip-path:${(props)=>(props.$isShow ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)")};
  transform:${(props)=>(props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 40px;
    line-height: 48px; /* 120% */
  }
`;

const ExplainEl = styled.div`
  width: 740px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    gap: 24px;
  }
`;

const MainExplainText = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 18px;
    line-height: 27px; /* 150% */
    margin-top: 40px;
  }
`;

const SubExplainEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubTitle = styled.span`
  color: #fff;
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  opacity: 0.64;
`;

const SubExplain = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
`;

const HighLight = styled.a`
  color: #3767ff;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  text-decoration: underline;
`;

const EllipseImg = styled.img`
  position: absolute;
  width: 3000px;
  bottom: 0px;
  left: 0px;
  transform: translate(-60%, 50%);
  z-index: -1;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

function InvolvedPart() {
  const [isTitleShow, setIsTitleShow] = useState(false);
  const [titleRef, titleInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if(titleInView){
    setIsTitleShow(true);
  }
  }, [titleInView]);
  return (
    <>
      <InvolvedPartEl>
        <Title ref={titleRef} $isShow={isTitleShow}>get Involved</Title>
        <ExplainEl>
          <MainExplainText>
            Join forces with us to create the most impactful crypto art
            conference up to date.
          </MainExplainText>
          <SubExplainEl>
            <SubTitle>Become A Sponsor</SubTitle>
            <SubExplain>
              Schedule a meeting with our sponsorship team via{" "}
              <HighLight
                href={"https://calendly.com/luciaverse/crypto-art-seoul"}
                target="_blank"
                rel="noreferrer"
              >
                Calendly
              </HighLight>
            </SubExplain>
          </SubExplainEl>
          <SubExplainEl>
            <SubTitle>All Inquiries</SubTitle>
            <SubExplain>
              Please contact{" "}
              <HighLight href={"mailto:sponsor@cryptoartseoul.com"}>
                sponsor@cryptoartseoul.com
              </HighLight>
            </SubExplain>
          </SubExplainEl>
        </ExplainEl>
        <EllipseImg src={"/images/Ellipse_1.png"} />
      </InvolvedPartEl>
    </>
  );
}

export default InvolvedPart;
