import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";

const MainEl = styled.div`
  width: 100%;
  min-height: 840px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  color: #ffffff;
  position: relative;
  transition: 1s;
  transition-delay: 0.5s;
  opacity: ${(props) => (props.$show ? "1" : "0")};
  @media (max-width: ${ScreenSizes.mobile}) {
    min-height: 924px;
  }
`;

const MainImgBox = styled.div`
  width: 100%;
  max-height: 810px;
  overflow: hidden;
  display: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 100%;
    max-height: 562px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }
`;

const MainImg = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: auto;
    height: 562px;
  }
`;

const TopEl = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
`;

const TopInnerEl = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 94px 80px 0px 80px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    padding: 54px 17px 0px 17px;
    gap: 24px;
  }
`;

const TopContentsEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TopTitleText = styled.span`
  font-size: 24px;
  font-family: Parabole;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const TopContentsText = styled.span`
  font-size: 40px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }
`;

const BottomEl = styled.div`
  position: absolute;
  bottom: 93px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    bottom: 0px;
  }
`;

const TicketEl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: rgba(28, 28, 28, 0.8);
  backdrop-filter: blur(16px);
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
  }
`;

const TicketInfoEl = styled.div`
  width: 412px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 80px 60px;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 293px;
    padding: 40px 24px;
    gap: 24px;
  }
`;

const TicketType = styled.span`
  color: #fff;
  text-align: center;

  /* Title 3 */
  font-family: Parabole;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    line-height: 16px; /* 114.286% */
  }
`;

const TicketExplain = styled.span`
  color: #fff;
  text-align: center;
  width: 372px;

  /* Subhead 2 */
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    line-height: 21px; /* 150% */
  }
`;

const TicketPrice = styled.span`
  color: ${(props) => (props.$isBlue ? "#3767ff" : "rgba(255,255,255,1)")};
  text-align: center;

  /* Title 2 */
  font-family: Parabole;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px; /* 112.5% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 40px;
    line-height: 48px; /* 120% */
  }
`;

const GetTicketButton = styled.button`
  display: flex;
  height: 48px;
  padding: 15px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 2000px;
  border: 1px solid #fff;
  background: none;

  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1.12px;
  text-transform: uppercase;

  cursor: pointer;

  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 12px 26px;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.96px;
    text-transform: uppercase;
  }
`;

const AvailableSoonButton = styled.button`
  display: flex;
  height: 48px;
  padding: 15px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 2000px;
  border: 1px solid #fff;
  opacity: 0.4;
  background: none;

  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1.12px;
  text-transform: uppercase;

  cursor: not-allowed;

  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 12px 26px;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.96px;
    text-transform: uppercase;
  }
`;

const MiddleBar = styled.div`
  width: 1px;
  height: 356px;
  opacity: 0.2;
  background: #d9d9d9;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const MobileMiddleBar = styled.div`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
    width: 293px;
    height: 1px;
    opacity: 0.2;
    background: #d9d9d9;
  }
`;

const TicketLeftCount = styled.span`
  position: absolute;
  bottom: 47px;
  color: rgba(255, 255, 255, 0.64);
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  @media (max-width: ${ScreenSizes.mobile}) {
    position: static;
  }
`;

const MintCountText = styled.span`
  color: ${(props) => (props.$isBlue ? "#3767ff" : "rgba(255,255,255,0.64)")};
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
`;

const MobileBr = styled.br`
display:none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display:inline-block;
  }
`;

function TicketsPart({ showComponents, setIsTicketModalOpen, mintCount }) {
  return (
    <>
      <a id="tickets"></a>
      <MainEl $show={showComponents}>
        <MainImgBox>
          <MainImg src={"/images/main_image_16_9.png"} />
          <TopEl>
            <TopInnerEl>
              <TopContentsEl>
                <TopTitleText>DATE</TopTitleText>
                <TopContentsText>
                  SEPTEMBER&nbsp;&nbsp;5 - 7&nbsp;&nbsp;2023
                </TopContentsText>
              </TopContentsEl>
              <TopContentsEl>
                <TopTitleText>LOCATION</TopTitleText>
                <TopContentsText>L’ESPACE ETNAH GODEOK<br/>(LIGHTROOM SEOUL)</TopContentsText>
              </TopContentsEl>
            </TopInnerEl>
          </TopEl>
          <BottomEl>
            <TicketEl>
              <TicketInfoEl>
                <TicketType>FIAT TICKETS</TicketType>
                <TicketExplain>
                Purchase tickets the oldschool,<br/>
                pre-hardfork way: with fiat.<br/>
                Tickets starting at
                </TicketExplain>
                <TicketPrice>180,000 KRW</TicketPrice>
                {/* <a
                  href={"https://cryptoartseoul.registrations.kr/"}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                > */}
                  {/* <GetTicketButton>sold out</GetTicketButton> */}
                {/* </a> */}
                <AvailableSoonButton>sold out</AvailableSoonButton>
              </TicketInfoEl>
              <MiddleBar />
              <MobileMiddleBar />
              <TicketInfoEl>
                <TicketType>NFT Superpass</TicketType>
                <TicketExplain>
                Purchase the NFT SUPERPASS, which{" "}<MobileBr/>comes with special benefits such as free{" "}<MobileBr/>entry for next year’s CAS and more.
                </TicketExplain>
                <TicketPrice $isBlue={mintCount === 100}>
                  {mintCount === 100 ? "SOLD OUT" : "0.2 ETH"}
                </TicketPrice>
                {mintCount === 100 ? (
                  <a
                    href={
                      "https://opensea.io/assets/ethereum/0x79FCF5Cfabd5a356DAbe99D924a04a0652281DC9"
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <GetTicketButton>Get NFT tickets</GetTicketButton>
                  </a>
                ) : (
                  <GetTicketButton
                    onClick={(e) => {
                      setIsTicketModalOpen(true);
                    }}
                  >
                    Get NFT tickets
                  </GetTicketButton>
                )}
                {/* <AvailableSoonButton>Available soon</AvailableSoonButton> */}
                {/* <TicketLeftCount>
                  <MintCountText $isBlue={mintCount > 0}>
                    {100 - mintCount}
                  </MintCountText>
                  /100 Left
                </TicketLeftCount> */}
              </TicketInfoEl>
            </TicketEl>
          </BottomEl>
        </MainImgBox>
      </MainEl>
    </>
  );
}
export default TicketsPart;
