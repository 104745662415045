import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyle1 = createGlobalStyle`
  html{
    scroll-behavior: smooth;
  }
`;
export const GlobalStyle2 = createGlobalStyle`
  html{
    overflow: hidden;
  }
`;

export const GlobalStyle3 = createGlobalStyle`
  html{
  }
`;
