import styled from "styled-components";
import { ScreenSizes } from "../../../styles/Themes";

const ArtistSlideItemEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 8px;
  }
`;

const ArtistExplainEl = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArtistName = styled.span`
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    line-height: 21px; /* 150% */
  }
`;

const ArtistTitle = styled.span`
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  opacity: 0.64;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 12px;
    line-height: 16px; /* 133.333% */
  }
`;

const ArtistImg = styled.img`
  width: 308px;
  height: 308px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 108px;
    height: 108px;
  }
`;

function ArtistSlideItem({ artist }) {
  return (
    <ArtistSlideItemEl>
      <ArtistImg src={"/artists/" + artist.imgName} />
      <ArtistExplainEl>
        <ArtistName>{artist.name}</ArtistName>
        <ArtistTitle>{artist.explain}</ArtistTitle>
      </ArtistExplainEl>
    </ArtistSlideItemEl>
  );
}

export default ArtistSlideItem;
