import styled from "styled-components";
import { useState, useEffect } from "react";
import { ScreenSizes } from "../../styles/Themes";
import { useInView } from "react-intersection-observer";

const VisitPartEl = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  gap:40px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const RightImg = styled.img`
  width: 524px;
  height: 393px;
  transition-delay: 2s;
  transition: 1s;
  opacity: ${(props) => (props.$show ? "1" : "0")};
`;

const VisitItmeEl = styled.div`
  width: 653px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
`;

const Title = styled.span`
  color: #fff;
  font-family: Parabole;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  text-transform: uppercase;
  transition: 1s;
  clip-path:${(props)=>(props.$isShow ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)")};
  transform:${(props)=>(props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
`;

const Explain = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
`;

const Location = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
  letter-spacing: -0.72px;
  text-transform: uppercase;
`;

const VisitButton = styled.button`
  display: flex;
  height: 48px;
  padding: 15px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2000px;
  border: 1px solid #fff;
  background: none;

  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
  cursor: pointer;
`;

const SuttleInfoEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SuttleInfoBox = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-end;
`;

const SuttleMap = styled.a`
  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  text-decoration: underline;
`;

const SiteLink = styled.a`
  text-decoration: none;
`;

const ItemBox = styled.div`
  width: 100%;
  height:auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 2;
`;

function VisitPart() {
  const [showImg1, setShowImg1] = useState(false);
  const [showImg2, setShowImg2] = useState(false);
  const [showImg3, setShowImg3] = useState(false);

  const [isTitle1Show, setIsTitle1Show] = useState(false);
  const [title1Ref, title1InView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [isTitle2Show, setIsTitle2Show] = useState(false);
  const [title2Ref, title2InView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [isTitle3Show, setIsTitle3Show] = useState(false);
  const [title3Ref, title3InView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (title1InView) {
      setIsTitle1Show(true);
      console.log("title1InView");
      setTimeout(() => {
        setShowImg1(true);
      }, 500);
    }
  }, [title1InView]);

  useEffect(() => {
    if (title2InView) {
      setIsTitle2Show(true);
      setTimeout(() => {
        setShowImg2(true);
      }, 500);
    }
  }, [title2InView]);

  useEffect(() => {
    if (title3InView) {
      setIsTitle3Show(true);
      setTimeout(() => {
        setShowImg3(true);
      }, 500);
    }
  }, [title3InView]);

  return (
    <>
      <VisitPartEl>
        <ItemBox>
          <VisitItmeEl>
            <Title ref={title1Ref} $isShow={isTitle1Show}>
              Location
            </Title>
            <Explain>CRYPTO ART SEOUL 2023 will take place at:</Explain>
            <Location>
            L’ESPACE ETNAH GODEOK (LIGHTROOM SEOUL)
              <br />
              103, ARISU-RO 61-GIL, seoul, south korea
            </Location>
            <SiteLink
              href={"https://goo.gl/maps/Z18YzXvZAEcjXuYGA"}
              target="_blank"
              rel="noreferrer"
            >
              <VisitButton>Google Map</VisitButton>
            </SiteLink>
          </VisitItmeEl>
          <RightImg src={"/images/visit_1.png"} $show={showImg1} />
        </ItemBox>
        <ItemBox>
          <VisitItmeEl>
            <Title ref={title3Ref} $isShow={isTitle3Show}>
              SHUTTLE BUS ROUTEs
            </Title>
            <Explain>
              Our shuttle buses, strategically routed to and from Seoul City’s
              major September festivities, double as standalone art experiences.
              Enjoy phenomenal works of art as you traverse through Seoul on
              your way to and from Crypto Art Seoul. 
            </Explain>
            <SiteLink
              href={"https://cryptoartseoul.com/public/CAS2023_shuttle_bus_information_for_website_0901.pdf"}
              target="_blank"
              rel="noreferrer"
            >
              <VisitButton>View Shuttle Bus Time Table</VisitButton>
            </SiteLink>
            <SuttleInfoEl>
              <SuttleInfoBox>
                <Location>SHUTTLE STOP 1 : COEX / UNCOMMON GALLERY</Location>
                <SuttleMap
                  href={"https://goo.gl/maps/ZeoqzVGN7KrHASkr5"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Google Map
                </SuttleMap>
              </SuttleInfoBox>
              <SuttleInfoBox>
                <Location>SHUTTLE STOP 2  :  VISTA WALKERHILL</Location>
                <SuttleMap
                  href={"https://goo.gl/maps/piLbSvxEioAYEt4SA"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Google Map
                </SuttleMap>
              </SuttleInfoBox>
              <SuttleInfoBox>
                <Location>SHUTTLE STOP 3  :  CHEONHO / GODEOK STATION</Location>
                <SuttleMap
                  href={"https://goo.gl/maps/Zv41K8fo2Fa2q9kT7"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Google Map
                </SuttleMap>
              </SuttleInfoBox>
            </SuttleInfoEl>
          </VisitItmeEl>
          <RightImg src={"/images/visit_3.png"} $show={showImg3} />
        </ItemBox>
        <ItemBox>
          <VisitItmeEl>
            <Title ref={title2Ref} $isShow={isTitle2Show}>
              PLAN YOUR STAY
            </Title>
            <Explain>
              Guests of Crypto Art Seoul can book rooms at Vista Walker Hill at
              a discounted rate during Sep 3 - Sep 8.
            </Explain>
            <Location>
              Mountain (Achasan) View: KRW 220,000 / night
              <br />
              Han River (Hangang) View: KRW 250,000 / night
            </Location>
            <SiteLink
              href={"https://bit.ly/3PxDoYg "}
              target="_blank"
              rel="noreferrer"
            >
              <VisitButton>BOOK A ROOM</VisitButton>
            </SiteLink>
          </VisitItmeEl>
          <RightImg src={"/images/visit_2.png"} $show={showImg2} />
        </ItemBox>
        </VisitPartEl>
    </>
  );
}

export default VisitPart;
