import styled from "styled-components";
import { ScreenSizes } from "../../../styles/Themes";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const ArtistsPartEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
`;

const TopEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 124px;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    gap: 40px;
  }
`;

const Title = styled.span`
  width: 416px;
  color: #fff;
  font-family: Parabole;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  text-transform: uppercase;
  transition: 1s;
  clip-path:${(props)=>(props.$isShow ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)")};
  transform:${(props)=>(props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    font-size: 40px;
    line-height: 48px; /* 120% */
  }
`;

const TopExplain = styled.span`
  width: 632px;
  color: #fff;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    font-size: 16px;
    line-height: 24px; /* 150% */
  }
`;

function ArtistsPart() {
  const [isTitleShow, setIsTitleShow] = useState(false);
  const [titleRef, titleInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if(titleInView){
    setIsTitleShow(true);
  }
  }, [titleInView]);
  return (
    <ArtistsPartEl>
      <TopEl>
        <Title ref={titleRef} $isShow={isTitleShow}>Art</Title>
        <TopExplain>
          Our exhibition showcases art from some of the most coveted
          crypto/digital artists and collectors from around the world, on
          ultra-large displays and curated sets.
        </TopExplain>
      </TopEl>
    </ArtistsPartEl>
  );
}

export default ArtistsPart;
