import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import SelectCountry from "./SelectCountry";
import { useWeb3React } from "@web3-react/core";
import { metaMask } from "../../lib/metamaskConnectors";
import { walletConnectV2 } from "../../lib/walletconnectConnectors";

const RightBackGround = styled.div`
  width: 50%;
  height: 100%;
  background-color: #f4f4f4;
  position: absolute;
  top: 0px;
  right: 0px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const TopWrapperEl = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    align-items: center;
  }
`;

const TopLeftEl = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 38px;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 107px;
    justify-content: flex-end;
    padding-bottom: 16px;
  }
`;

const TicketImg = styled.img`
  width: 384px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const ConnectedWalletIcon = styled.div`
  border-radius: 8px;
  border: 1px solid #000;
  padding: 8px 12px;
  position: absolute;
  bottom: 47px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const ConnectedWalletImg = styled.img`
  height: 16px;
`;

const TopLeftTitle = styled.span`
  color: #000;
  text-align: center;
  font-family: Parabole;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 112.5% */
  text-transform: uppercase;
`;

const TopRightEl = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    background: #f4f4f4;
    padding: 59px 16px 48px 16px;
    justify-content: flex-start;
    box-sizing: border-box;
    gap: 8px;
  }
`;

const ProgressEl = styled.div`
  position: absolute;
  left: 48px;
  top: 48px;
  gap: 10px;
  display: flex;
  @media (max-width: ${ScreenSizes.mobile}) {
    left: 16px;
    top: 32px;
  }
`;

const ProgressBar1 = styled.div`
  width: 32px;
  height: 3px;
  border-radius: 100px;
  background: #3767ff;
`;

const ProgressBar2 = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 100px;
  background: #000;
`;

const ProgressBar3 = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 100px;
  background: #000;
`;

const ProgressTitle = styled.span`
  position: absolute;
  left: 48px;
  top: 89px;
  color: #000;
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    position: static;
    width: 100%;
    text-align: left;
  }
`;

const TellusEl = styled.div`
  width: 616px;
  position: absolute;
  top: 113px;
  left: 19px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    position: static;
  }
`;

const Highlight = styled.span`
  color: #3767ff;
  cursor: pointer;
`;

const HighlightBold = styled.span`
  color: #3767ff;
  font-weight: 600;
`;

const TellusExplain = styled.span`
  width: 100%;
  margin-top: 16px;
  color: #000;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  @media (max-width: ${ScreenSizes.mobile}) {
    margin-top: 8px;
  }
`;

const TellusGm = styled.span`
  margin-top: 4px;
  color: #000;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  @media (max-width: ${ScreenSizes.mobile}) {
    margin-top: 16px;
    font-size: 12px;
    line-height: 16px;
  }
`;

const InputInfoEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 8px;
    margin-top: 14px;
  }
`;

const InputTopEl = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
  }
`;

const InputEl = styled.input`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #000;
  padding: 8px 12px;
  box-sizing: border-box;
  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
  }
`;

const AgreePolicyEl = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 10px;
`;

const AgreeCheckButton = styled.div`
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 200px;
  cursor: pointer;
`;

const AgreeCheckedButton = styled.div`
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 200px;
  border: 3px solid #3767ff;
  cursor: pointer;
`;

const AgreeText = styled.span`
  color: #000;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
`;

const PolicyLink = styled.a`
  color: #3767ff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
`;

const StepEl = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  @media (max-width: ${ScreenSizes.mobile}) {
    margin-top: 24px;
  }
`;

const BackButton = styled.img`
  width: 32px;
  cursor: pointer;
`;

const CheckoutButton = styled.div`
  padding: 8px 16px;
  background-color: ${(props) =>
    props.$isReady ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.4)"};
  border-radius: 2000px;
  box-sizing: border-box;

  color: #fff;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.96px;
  text-transform: uppercase;

  cursor: ${(props) => (props.$isReady ? "pointer" : "not-allowed")};
`;

function TicketTellus({
  setIsPrivacyModalOpen,
  setStep,
  setUserName,
  setUserMail,
  setUserCountry,
}) {
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);
  const [isProceedReady, setIsProceedReady] = useState(false);
  const nameRef = useRef(null);
  const mailRef = useRef(null);
  const countryRef = useRef(null);
  const { provider } = useWeb3React();

  useEffect(()=>{
    console.log("provider : ",provider.connection.url);
    setUserName("")
    setUserMail("");
    setUserCountry("");
  },[]);

  function backToWalletConnect() {
    metaMask.resetState();
    walletConnectV2.deactivate();
    setStep(0);
  }

  function checkEmailFormat(email) {
    const regex = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  }

  function proceedHandler() {
    if (!isProceedReady) return;
    if (!checkEmailFormat(mailRef.current.value)) {
      alert("*Incorrect email format. Please check and try again.");
      return;
    }
    console.log("nameRef : ", nameRef.current.value);
    console.log("mailRef : ", mailRef.current.value);
    console.log("countryRef : ", countryRef.current.value);
    setUserName(nameRef.current.value)
    setUserMail(mailRef.current.value);
    setUserCountry(countryRef.current.value);
    setStep(2);
  }

  function buttonActiveHandler() {
    if (
      nameRef.current.value !== "" &&
      mailRef.current.value !== "" &&
      isPolicyChecked
    ) {
      setIsProceedReady(true);
    } else {
      setIsProceedReady(false);
    }
  }

  function policyToggle() {
    if (
      nameRef.current.value !== "" &&
      mailRef.current.value !== "" &&
      !isPolicyChecked
    ) {
      setIsProceedReady(true);
    } else {
      setIsProceedReady(false);
    }
    setIsPolicyChecked(!isPolicyChecked);
  }
  return (
    <>
      <RightBackGround />
      <TopWrapperEl>
        <TopLeftEl>
          <TopLeftTitle>NFT Ticketing</TopLeftTitle>
          <TicketImg src={"/images/ticket.png"} />
          {provider.connection.url === "metamask" ? (
            <ConnectedWalletIcon>
              <ConnectedWalletImg src={"/images/metamask.svg"} />
            </ConnectedWalletIcon>
          ) : (
            <ConnectedWalletIcon>
              <ConnectedWalletImg src={"/images/full-walletconnect-logo.svg"} />
            </ConnectedWalletIcon>
          )}
        </TopLeftEl>
        <TopRightEl>
          <ProgressEl>
            <ProgressBar2 />
            <ProgressBar1 />
            <ProgressBar3 />
          </ProgressEl>
          <ProgressTitle>tell us about you</ProgressTitle>
          <TellusEl>
            <TellusExplain>
              We are asking for the following information so that we can provide
              you with necessary information and a more tailored visit to Crypto
              Art Seoul. We take the handling of your personal information very
              seriously - please check our{" "}
              <Highlight
                onClick={(e) => {
                  setIsPrivacyModalOpen(true);
                }}
              >
                privacy policy
              </Highlight>{" "}
              for a detailed explanation.
            </TellusExplain>
            <TellusGm>
              GM,{" "}
              <HighlightBold>
                0xaD99a67ac78b80E00C0B07bB3F526Cd26B843611
              </HighlightBold>{" "}
              !
            </TellusGm>
            <InputInfoEl>
              <InputTopEl>
                <InputEl
                  placeholder={"Name / Nickname*"}
                  ref={nameRef}
                  onChange={buttonActiveHandler}
                ></InputEl>
                <InputEl
                  placeholder={"Email Address*"}
                  ref={mailRef}
                  onChange={buttonActiveHandler}
                ></InputEl>
              </InputTopEl>
              {/* <InputEl placeholder={"Country"}></InputEl> */}
              <SelectCountry propRef={countryRef} />
            </InputInfoEl>
            <AgreePolicyEl>
              {!isPolicyChecked && <AgreeCheckButton onClick={policyToggle} />}
              {isPolicyChecked && <AgreeCheckedButton onClick={policyToggle} />}
              <AgreeText>
                I have read and agree to the{" "}
                <PolicyLink
                  onClick={(e) => {
                    setIsPrivacyModalOpen(true);
                  }}
                >
                  privacy policy
                </PolicyLink>
              </AgreeText>
            </AgreePolicyEl>
            <StepEl>
              <BackButton
                src={"/icons/back.svg"}
                onClick={backToWalletConnect}
              />
              <CheckoutButton
                onClick={proceedHandler}
                $isReady={isProceedReady}
              >
                PROCEED TO CHECKOUT
              </CheckoutButton>
            </StepEl>
          </TellusEl>
        </TopRightEl>
      </TopWrapperEl>
    </>
  );
}
export default TicketTellus;
