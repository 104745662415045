import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  addDoc,
  collection,
  serverTimestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyDV6sKzGVlqJQtVGGpyAMWgKT8kcrUp9Ao",
//   authDomain: "crypto-art-seoul.firebaseapp.com",
//   projectId: "crypto-art-seoul",
//   storageBucket: "crypto-art-seoul.appspot.com",
//   messagingSenderId: "466923176368",
//   appId: "1:466923176368:web:c8ee9673734dde5a921675",
//   measurementId: "G-VVFJWJR6W7"
// };

const firebaseConfig = {
  apiKey: "AIzaSyB7WUs6adcHBPg1L9ngVzg2S6s7OzML4bk",
  authDomain: "cas2023-test.firebaseapp.com",
  projectId: "cas2023-test",
  storageBucket: "cas2023-test.appspot.com",
  messagingSenderId: "106739317592",
  appId: "1:106739317592:web:2d466a7e2e07a742c5832d",
  measurementId: "G-XXFRS6TY1M",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const initialServerSlice = {
  status: 0,
  isDupe: false,
};

// 0 : WAIT
// 1 : SAVING
// 2 : FINISH
// 3 : ERROR

const serverSlice = createSlice({
  name: "serverSlice",
  initialState: initialServerSlice,
  reducers: {
    initiateStatus(state) {
      state.status = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncSaveBeforeMint.pending, (state, action) => {
        state.status = 1;
      })
      .addCase(asyncSaveBeforeMint.fulfilled, (state, action) => {
        if (action.payload === "dupe") {
          state.isDupe = true;
          console.log("dupe");
        }
        state.status = 2;
      })
      .addCase(asyncSaveBeforeMint.rejected, (state, action) => {
        state.status = 3;
        console.log(action.payload);
      });

    builder
      .addCase(asyncSaveAfterMint.pending, (state, action) => {
        state.status = 1;
      })
      .addCase(asyncSaveAfterMint.fulfilled, (state, action) => {
        if (action.payload === "dupe") {
          state.isDupe = true;
          console.log("dupe");
        }
        state.status = 2;
      })
      .addCase(asyncSaveAfterMint.rejected, (state, action) => {
        state.status = 3;
        console.log(action.payload);
      });
  },
});

const asyncSaveBeforeMint = createAsyncThunk(
  "serverSlice/asyncSaveBeforeMint",
  async (params, { rejectWithValue }) => {
    const { userName, userMail, userCountry, account } = params;
    try {
      const databaseRef = collection(db, "cas2023_nft");
      const q = query(
        databaseRef,
        where("name", "==", userName),
        where("email", "==", userMail),
        where("country", "==", userCountry),
        where("walletAddress", "==", account),
        where("isMint", "==", false)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size > 0) {
        let result = "dupe";
        return result;
      } else {
        let result = await addDoc(collection(db, "cas2023_nft"), {
          name: userName,
          email: userMail,
          country: userCountry,
          walletAddress: account,
          isMint: false,
          time: serverTimestamp(),
        });
        return result;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const asyncSaveAfterMint = createAsyncThunk(
  "serverSlice/asyncSaveAfterMint",
  async (params, { rejectWithValue }) => {
    const { userName, userMail, userCountry, account } = params;
    try {
      const databaseRef = collection(db, "cas2023_nft");
      const q = query(
        databaseRef,
        where("name", "==", userName),
        where("email", "==", userMail),
        where("country", "==", userCountry),
        where("walletAddress", "==", account),
        where("isMint", "==", true)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size > 0) {
        let result = "dupe";
        return result;
      } else {
        let result = await addDoc(collection(db, "cas2023_nft"), {
          name: userName,
          email: userMail,
          country: userCountry,
          walletAddress: account,
          isMint: true,
          time: serverTimestamp(),
        });
        return result;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export { asyncSaveBeforeMint, asyncSaveAfterMint };
export const serverActions = serverSlice.actions;
export default serverSlice.reducer;
