export const ScreenSizes = {
    tablet : '1200px',
    mobile : '500px',
}

export const Colors = {
    gr01 : '#050505',
    gr02 : '#141414',
    gr03 : '#292929',
    gr04 : '#3D3D3D',
    gr05 : '#5C5C5C',
    gr06 : '#808080',
    gr07 : '#BBBBBB',
    gr08 : '#F2F2F2',
    gr600 : '#303036',
    wt: '#FFFFFF',
    highlight : '#fffb9a',
    warning: '#F94D4D',
}

// export const Sizes = {
//     HeaderHeight : '52px',
//     MobileHeaderHeight : '40px',
//     FooterHeight : '96px',
//     MobileFooterHeight : '144px',
// }