import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const ExhibitionPartEl = styled.div`
  padding: 120px 0px 120px 0px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 80px 0px;
  }
`;

const Title = styled.span`
  width: 100%;
  color: #fff;
  font-family: Parabole;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  text-transform: uppercase;
  transition: 1s;
  clip-path:${(props)=>(props.$isShow ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)")};
  transform:${(props)=>(props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 40px;
    line-height: 48px; /* 120% */
  }
`;

const ExhibitionListEl = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    overflow-y: hidden;
    gap: 8px;
  }
`;

const ExhibitionListItem = styled.div`
  padding-left: 16px;
  width: 309px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(255, 255, 255, 0.20);
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 283px;
    padding-left: 8px;
  }
`;

const ExhibitionNumber = styled.span`
  color: #fff;
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
`;

const ExhibitionTitle = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
  letter-spacing: -0.72px;
  text-transform: uppercase;
  margin-top: 40px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 283px;
    font-size: 20px;
    line-height: 24px; /* 120% */
    letter-spacing: -0.6px;
    margin-top: 32px;
  }
`;

const ExhibitionContents = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    line-height: 21px; /* 150% */
    margin-top: 12px;
  }
`;

const PCBr = styled.br`
  display: inline-block;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

function ExhibitionPart() {
  const [isTitleShow, setIsTitleShow] = useState(false);
  const [titleRef, titleInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if(titleInView){
    setIsTitleShow(true);
  }
  }, [titleInView]);
  return (
    <>
      <ExhibitionPartEl>
        <Title ref={titleRef} $isShow={isTitleShow}>
          art{" "}
          <PCBr />
          Exhibition
        </Title>
        <ExhibitionListEl>
          <ExhibitionListItem>
            <ExhibitionNumber>01</ExhibitionNumber>
            <ExhibitionTitle>Special Exhibition </ExhibitionTitle>
            <ExhibitionContents>
              A curated exhibition of artworks that befit this year's theme,
              'Hardforking Humanity
            </ExhibitionContents>
          </ExhibitionListItem>
          <ExhibitionListItem>
            <ExhibitionNumber>02</ExhibitionNumber>
            <ExhibitionTitle>Open-call Exhibition</ExhibitionTitle>
            <ExhibitionContents>
              An open call for digital and crypto artists who are at the
              forefront of humanity's hardfork
            </ExhibitionContents>
          </ExhibitionListItem>
          <ExhibitionListItem>
            <ExhibitionNumber>03</ExhibitionNumber>
            <ExhibitionTitle>Partner Exhibition</ExhibitionTitle>
            <ExhibitionContents>
              Special exhibitions by partners that denote various artistic
              endeavors in crypto art.
            </ExhibitionContents>
          </ExhibitionListItem>
          <ExhibitionListItem>
            <ExhibitionNumber>04</ExhibitionNumber>
            <ExhibitionTitle>Art Performance</ExhibitionTitle>
            <ExhibitionContents>
              Jaw-dropping performances by artists that encourage a shift in our
              perception and common sense
            </ExhibitionContents>
          </ExhibitionListItem>
        </ExhibitionListEl>
      </ExhibitionPartEl>
    </>
  );
}

export default ExhibitionPart;
