import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { ScreenSizes } from '../../../styles/Themes';

const PartnersPartEl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 72px;
  margin-top:120px;
  @media (max-width: ${ScreenSizes.mobile}) {
    margin-top: 80px;
    flex-direction: column;
    gap: 24px;
  }
`;

const Title = styled.span`
  width: 360px;
  color: #fff;
  font-family: Parabole;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  text-transform: uppercase;
  transition: 1s;
  clip-path:${(props)=>(props.$isShow ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)")};
  transform:${(props)=>(props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    text-align: left;
    font-size: 40px;
    line-height: 48px; /* 120% */
    text-transform: uppercase;
  }
`;

const CompanyListWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CompanyListEl = styled.div`
  width: 850px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    gap: 8px;
  }
`;

const CompanyBox = styled.div`
  width: 272px;
  height: 272px;
  border-radius: 16px;
  background: #141414;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 109px;
    height: 109px;
    border-radius: 16px;
  }
`;

const CompanyImgPC = styled.img`
  display: inline-block;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const CompanyImgMobile = styled.img`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
  }
`;

function FoundingPartnersPart() {
  const [isTitleShow, setIsTitleShow] = useState(false);
  const [titleRef, titleInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if(titleInView){
    console.log("title show");
    setIsTitleShow(true);
  }
  }, [titleInView]);
  return (
    <PartnersPartEl>
      <Title ref={titleRef} $isShow={isTitleShow}>Founding<br/>Partners</Title>
      <CompanyListWrapper>
        <CompanyListEl>
        <a
            href={"https://www.galleryhyundai.com"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"153px"} src={"/logos/01 - gallery hyundai.png"} />
              <CompanyImgMobile width={"61.3px"} src={"/logos/01 - gallery hyundai.png"} />
            </CompanyBox>
          </a>
        <a
            href={"https://www.superchiefgallery.com/"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"128.3px"} src={"/logos/02 - Superchief Gallery NFT Logomark_White.png"} />
              <CompanyImgMobile width={"56.1px"} src={"/logos/02 - Superchief Gallery NFT Logomark_White.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://www.nonfungibleconference.com"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"160px"} src={"/logos/NFC23_outline_full_white.png"} />
              <CompanyImgMobile width={"64.1px"} src={"/logos/NFC23_outline_full_white.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://handao.org/"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"93px"} src={"/logos/03 - HanDAO 2.png"} />
              <CompanyImgMobile width={"41.3px"} src={"/logos/03 - HanDAO 2.png"} />
            </CompanyBox>
          </a>
        </CompanyListEl>
      </CompanyListWrapper>
    </PartnersPartEl>
  );
}

export default FoundingPartnersPart;
