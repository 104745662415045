import { useState } from 'react';
import styled, { keyframes } from "styled-components";

const rotateTriangleAnimation = keyframes`
  0% {
    transform: rotateX(0deg);
  }
  24%, 34% {
    transform: rotateX(120deg);
  }
  58%, 67% {
    transform: rotateX(240deg);
  }
  91%, 100% {
    transform: rotateX(360deg);
  }
`;

const rotateTriangleHoverAnimation = keyframes`
  58%, 67% {
    display:inline-block;
  }
  100% {
    transform: rotateX(120deg);
  }
`;

const Container = styled.div`
  --translationDistance: 15px;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-template-rows: repeat(3, 33%);
  justify-items: center;
  align-items: center;
`;

const Scene = styled.div`
  position: relative;
  width: 150px;
  height: 42px;
  grid-row: 2/3;
  grid-column: 2/3;
  perspective: 500px;
`;

const Triangle = styled.div`
  width: inherit;
  height: inherit;
  position: relative;
  transform-style: preserve-3d;
  &:hover {
    animation: ${rotateTriangleHoverAnimation} 0.3s linear forwards;
  }
`;

const TriangleFace = styled.div`
  width: inherit;
  height: inherit;
  position: absolute;
  background: none;
  //background:black;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
`;

const FontBox = styled.span`
  padding: 2px 6px;
  border-radius: 8px;
  background: ${(props) => (props.$isSelected ? "#3767ff" : "none")};
`;

const FrontFace = styled.div`
  opacity: ${(props) => (props.$isVisible ? "1" : "0")};
  transform: translate3d(0, 0, var(--translationDistance));
  width: inherit;
  height: inherit;
  position: absolute;
  background: none;
  //background:black;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
`;

const LeftFace = styled.div`
  opacity: ${(props) => (props.$isVisible ? "1" : "0")};
  transform: rotateX(-120deg) translate3d(0, 0, var(--translationDistance));
  width: inherit;
  height: inherit;
  position: absolute;
  background: none;
  //background:black;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
`;

const RightFace = styled.div`
  transform: rotateX(120deg) translate3d(0, 0, var(--translationDistance));
`;

function HeaderMenuItem({ children, isSelected }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isFrontFaceVisible, setIsFrontFaceVisible] = useState(true);
  function mouseLeaveHandler(){
    setIsFrontFaceVisible(true);
    setIsVisible(false);
  }
  function animationEndHandler(){
    setIsFrontFaceVisible(false);
  }
  return (
    <Container>
      <Scene>
        <Triangle onMouseEnter={(e)=>{setIsVisible(true)}} onMouseLeave={mouseLeaveHandler} onAnimationEnd={animationEndHandler}>
          <FrontFace $isVisible={isFrontFaceVisible} >
            <FontBox $isSelected={isSelected}>{children}</FontBox>
          </FrontFace>
          <LeftFace $isVisible={isVisible}>
            <FontBox $isSelected={isSelected}>{children}</FontBox>
          </LeftFace>
          <RightFace>
            <FontBox></FontBox>
          </RightFace>
        </Triangle>
      </Scene>
    </Container>
  );
}
export default HeaderMenuItem;
