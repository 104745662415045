import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ArtistSlideItem from "./ArtistSlideItem";
import { ScreenSizes } from "../../../styles/Themes";

const SlideContainer = styled.div`
  margin-top: 64px;
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  align-items: center;
  overflow-x: hidden;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 95vw;
    margin-top: 16px;
    overflow-y: hidden;
  }
`;

const InnerCarouselEl = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.$itemCount / 2}, 1fr);
  gap: 8px;
  transition: 1s ease;
  transform: translate(${(props) => props.$sliderCount}, 0);
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const LeftArrow = styled.span`
  position: absolute;
  left: 0;
  color: #fff;
  font-size: 40px;
  z-index: 2;
  transition: 0.5s;
  opacity: ${(props) => (props.$isHover ? "1" : "0")};
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const RigthArrow = styled.span`
  position: absolute;
  right: 0;
  color: #fff;
  font-size: 40px;
  z-index: 2;
  transition: 0.5s;
  opacity: ${(props) => (props.$isHover ? "1" : "0")};
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const MobileSliderWrapperEl = styled.div`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
    overflow: scroll;
  }
`;

const MobileInnerCarouselEl = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.$itemCount}, 1fr);
  gap: 8px;
`;

const ArtistList = [
  { name: "6529", explain: "6529 Collection", imgName: "6529.png" },
  { name: "eobchae", explain: "Audiovisual Production Unit", imgName: "eobchae.png" },
  { name: "Jinah Roh", explain: "New Media Artist", imgName: "JinahRoh.png" },
  { name: "Leo Villareal", explain: "Light Artist", imgName: "LeoVillareal.png" },
  { name: "Mix.Audio", explain: "Web 3 Music Platform", imgName: "MixAudio.png" },
  { name: "Nakta", explain: "Crypto Artist", imgName: "Nakta.png" },
  { name: "Pak", explain: "The Nothing", imgName: "Pak.png" },
  {
    name: "Pauline Faieff",
    explain: "Crypto Artist",
    imgName: "PaulineFaieff.png",
  },
  { name: "Ryan Gander", explain: "Artist", imgName: "RyanGander.png" },
  { name: "The Sandbox", explain: "Metaverse", imgName: "TheSandbox.png" },
  { name: "Yoyojin", explain: "Crypto Artist", imgName: "Yoyojin.png" },
  { name: "differentia\\\\", explain: "Modular Research Collective", imgName: "differentia.png" },
  { name: "Chun Myoung-hwan", explain: "Particular Film", imgName: "ChunMyoung-hwan.png" },
  { name: "Hackatao", explain: "Crypto Artist", imgName: "Hackatao.png" },
  { name: "Joy Jo", explain: "Crypto Artist", imgName: "JoyJo.png" },
  { name: "LuYang", explain: "Artist", imgName: "LuYang.png" },
  { name: "MrMisang", explain: "Crypto Artist", imgName: "MrMisang.png" },
  { name: "NFC", explain: "Web 3 Conference", imgName: "NFC.png" },
  { name: "Patrick Amadon", explain: "Crypto Artist", imgName: "PatrickAmadon.png" },
  { name: "Regina Kim", explain: "Crypto Artist", imgName: "ReginaKim.png" },
  {
    name: "Superchief Gallery NFT",
    explain: "NFT Gallery",
    imgName: "SuperchiefGalleryNFT.png",
  },
  { name: "Uttermelon", explain: "Crypto Artist", imgName: "Uttermelon.png" },
  { name: "YuYu", explain: "Crypto Artist", imgName: "YuYu.png" },
  { name: "TBA", explain: "TBA", imgName: "TBA.png" },
];

const MobileArtistList = [
  { name: "6529", explain: "6529 Collection", imgName: "6529.png" },
  { name: "Chun Myoung-hwan", explain: "Particular Film", imgName: "ChunMyoung-hwan.png" },
  { name: "eobchae", explain: "Audiovisual Production Unit", imgName: "eobchae.png" },
  { name: "Hackatao", explain: "Crypto Artist", imgName: "Hackatao.png" },
  { name: "Jinah Roh", explain: "New Media Artist", imgName: "JinahRoh.png" },
  { name: "Joy Jo", explain: "Crypto Artist", imgName: "JoyJo.png" },
  { name: "Leo Villareal", explain: "Light Artist", imgName: "LeoVillareal.png" },
  { name: "LuYang", explain: "Artist", imgName: "LuYang.png" },
  { name: "Mix.Audio", explain: "Web 3 Music Platform", imgName: "MixAudio.png" },
  { name: "MrMisang", explain: "Crypto Artist", imgName: "MrMisang.png" },
  { name: "Nakta", explain: "Crypto Artist", imgName: "Nakta.png" },
  { name: "NFC", explain: "Web 3 Conference", imgName: "NFC.png" },
  { name: "Pak", explain: "The Nothing", imgName: "Pak.png" },
  { name: "Patrick Amadon", explain: "Crypto Artist", imgName: "PatrickAmadon.png" },
  {
    name: "Pauline Faieff",
    explain: "Crypto Artist",
    imgName: "PaulineFaieff.png",
  },
  { name: "Regina Kim", explain: "Crypto Artist", imgName: "ReginaKim.png" },
  { name: "Ryan Gander", explain: "Artist", imgName: "RyanGander.png" },
  {
    name: "Superchief Gallery NFT",
    explain: "NFT Gallery",
    imgName: "SuperchiefGalleryNFT.png",
  },
  { name: "The Sandbox", explain: "Metaverse", imgName: "TheSandbox.png" },
  { name: "Uttermelon", explain: "Crypto Artist", imgName: "Uttermelon.png" },
  { name: "Yoyojin", explain: "Crypto Artist", imgName: "Yoyojin.png" },
  { name: "YuYu", explain: "Crypto Artist", imgName: "YuYu.png" },
  { name: "differentia\\\\", explain: "Modular Research Collective", imgName: "differentia.png" },
];

function ArtistSlider() {
  const [isHover, setIsHover] = useState(false);
  const [sliderCount, setSliderCount] = useState(0);
  const [maxSlideCount, setMaxSlideCount] = useState(0);
  const wrapperRef = useRef(null);
  const innerRef = useRef(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const inner = innerRef.current;
    setMaxSlideCount(Math.ceil((inner.offsetWidth - wrapper.offsetWidth) / 316));

    function handleResize() {
      setMaxSlideCount(Math.ceil((inner.offsetWidth - wrapper.offsetWidth) / 316));
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function leftHandler() {
    if (0 < sliderCount) {
      setSliderCount(sliderCount - 1);
    }
  }

  function rightHandler() {
    if (sliderCount < maxSlideCount) {
      setSliderCount(sliderCount + 1);
    }
  }

  return (
    <>
      <SlideContainer
        ref={wrapperRef}
        onMouseEnter={(e) => {
          setIsHover(true);
        }}
        onMouseLeave={(e) => {
          setIsHover(false);
        }}
      >
        <LeftArrow onClick={leftHandler} $isHover={isHover}>
          {"<"}
        </LeftArrow>
        <InnerCarouselEl
          ref={innerRef}
          $itemCount={ArtistList.length}
          $sliderCount={-316 * sliderCount + "px"}
        >
          {ArtistList.map((artist, index) => (
            <ArtistSlideItem key={index} artist={artist}></ArtistSlideItem>
          ))}
        </InnerCarouselEl>
        <RigthArrow onClick={rightHandler} $isHover={isHover}>
          {">"}
        </RigthArrow>
        <MobileSliderWrapperEl>
          <MobileInnerCarouselEl $itemCount={ArtistList.length}>
            {MobileArtistList.map((artist, index) => (
              <ArtistSlideItem key={index} artist={artist}></ArtistSlideItem>
            ))}
          </MobileInnerCarouselEl>
        </MobileSliderWrapperEl>
      </SlideContainer>
    </>
  );
}

export default ArtistSlider;
