import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const SchedulePartEl = styled.div`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    gap: 40px;
  }
`;

const Title = styled.span`
  color: var(--white, #fff);
  font-family: Parabole;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 120% */
  text-transform: uppercase;
  transition: 1s;
  clip-path: ${(props) =>
    props.$isShow
      ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
      : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"};
  transform: ${(props) => (props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
`;

const DaySelectEl = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SelectDayButton = styled.div`
  display: flex;
  padding: 4px 17px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.$isSelected ? "#3767ff" : "none")};

  color: #fff;
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
`;

const TypeSelectEl = styled.div`
  width: 100%;
  display: flex;
`;

const TypeButton = styled.div`
  width: 50%;
  text-align: center;
  color: ${(props) => (props.$isSelected ? "#3767ff" : "#fff")};
  font-family: Parabole;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 114.286% */
  text-transform: uppercase;
  border-bottom: 1px solid ${(props) => (props.$isSelected ? "#3767ff" : "rgba(217,217,217,0.2)")};
`;

const BottomEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 150px;
`;

const ExhibitionBox = styled.div`
  width: 100%;
  padding: 16px 8px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgba(55, 103, 255, 0.8);
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const ConferenceBox = styled.div`
  width: 100%;
  padding: 16px 8px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const PartyBox = styled.div`
  width: 100%;
  padding: 16px 8px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgba(151, 71, 255, 0.8);
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const TimeText = styled.span`
  color: var(--crypto-blue, #3767ff);
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

const ContentsEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
`;

const ContentSubTitle = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  opacity: 0.6;
`;

function SchedulePartMobile() {
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedType, setSelectedType] = useState(0);
  const [isTitleShow, setIsTitleShow] = useState(false);
  const [titleRef, titleInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (titleInView) {
      console.log("title show");
      setIsTitleShow(true);
    }
  }, [titleInView]);
  return (
    <SchedulePartEl>
      <Title ref={titleRef} $isShow={isTitleShow}>
        FULL
        <br />
        SCHEDULE
      </Title>
      <DaySelectEl>
        <SelectDayButton
          $isSelected={selectedDay === 0}
          onClick={(e) => {
            setSelectedDay(0);
          }}
        >
          Sep 5 (tue)
        </SelectDayButton>
        <SelectDayButton
          $isSelected={selectedDay === 1}
          onClick={(e) => {
            setSelectedDay(1);
          }}
        >
          Sep 6 (wed)
        </SelectDayButton>
        <SelectDayButton
          $isSelected={selectedDay === 2}
          onClick={(e) => {
            setSelectedDay(2);
          }}
        >
          Sep 7 (thur)
        </SelectDayButton>
      </DaySelectEl>
      <BottomEl>
        <TypeSelectEl>
          <TypeButton
            $isSelected={selectedType === 0}
            onClick={(e) => {
              setSelectedType(0);
            }}
          >
            exhibition
          </TypeButton>
          <TypeButton
            $isSelected={selectedType === 1}
            onClick={(e) => {
              setSelectedType(1);
            }}
          >
            conference
          </TypeButton>
          <TypeButton
            $isSelected={selectedType === 2}
            onClick={(e) => {
              setSelectedType(2);
            }}
          >
            Party
          </TypeButton>
        </TypeSelectEl>
        {/* SEP 5 - EXHIBITION */}
        {selectedDay === 0 && selectedType === 0 && (
          <>
            <ExhibitionBox>
              <TimeText>11:00 - 20:30</TimeText>
              <ContentsEl>
                <ContentBox>
                  <ContentTitle>
                    Mobility Experience
                    <br />
                    (Shuttle Bus Exhibition)
                  </ContentTitle>
                </ContentBox>
                <ContentBox>
                  <ContentTitle>Special Exhibition</ContentTitle>
                </ContentBox>
                <ContentBox>
                  <ContentTitle>Open-call Exhibition</ContentTitle>
                </ContentBox>
                <ContentBox>
                  <ContentTitle>Partner Exhibition</ContentTitle>
                </ContentBox>
              </ContentsEl>
              <br/>
              <TimeText>20:30 - 21:00</TimeText>
              <ContentsEl>
                <ContentBox>
                  <ContentTitle>Art Performance</ContentTitle>
                </ContentBox>
              </ContentsEl>
            </ExhibitionBox>
          </>
        )}
        {/* SEP 5 - CONFERENCE */}
        {selectedDay === 0 && selectedType === 1 && (
          <>
            <ConferenceBox>
              <TimeText>14:30 - 15:00</TimeText>
              <ContentsEl>
                <ContentBox>
                  <ContentTitle>Opening Ceremony</ContentTitle>
                </ContentBox>
              </ContentsEl>
              <br/>
              <TimeText>15:00 - 16:30</TimeText>
              <ContentsEl>
                <ContentBox>
                  <ContentTitle>Section 1: #Awareness</ContentTitle>
                  <ContentSubTitle>Make art exist in the world</ContentSubTitle>
                </ContentBox>
              </ContentsEl>
              <br/>
              <TimeText>16:30 - 17:00</TimeText>
              <ContentsEl>
                <ContentBox>
                  <ContentTitle>Break</ContentTitle>
                </ContentBox>
              </ContentsEl>
              <br/>
              <TimeText>17:00 - 20:30</TimeText>
              <ContentsEl>
                <ContentBox>
                  <ContentTitle>Section 2: #Manifestation</ContentTitle>
                  <ContentSubTitle>
                    Recognize the beacons of the world, and let them shine free
                  </ContentSubTitle>
                </ContentBox>
                <ContentBox>
                  <ContentTitle>Networking Party</ContentTitle>
                </ContentBox>
              </ContentsEl>
            </ConferenceBox>
          </>
        )}
        {/* SEP 5 - PARTY */}
        {selectedDay === 0 && selectedType === 2 && (
          <>
            <PartyBox>
              <TimeText>21:00 - 23:00</TimeText>
              <ContentsEl>
                <ContentBox>
                  <ContentTitle>
                    Hahoetal Masquerade - Networking Party
                    <br />
                    (Co-hosted by The Sandbox)
                  </ContentTitle>
                </ContentBox>
              </ContentsEl>
            </PartyBox>
          </>
        )}
        {/* SEP 6 - EXHIBITION */}
        {selectedDay === 1 && selectedType === 0 && (
          <ExhibitionBox>
            <TimeText>11:00 - 19:00</TimeText>
            <ContentsEl>
              <ContentBox>
                <ContentTitle>
                  Mobility Experience
                  <br />
                  (Shuttle Bus Exhibition)
                </ContentTitle>
              </ContentBox>
              <ContentBox>
                <ContentTitle>Special Exhibition</ContentTitle>
              </ContentBox>
              <ContentBox>
                <ContentTitle>Open-call Exhibition</ContentTitle>
              </ContentBox>
              <ContentBox>
                <ContentTitle>Partner Exhibition</ContentTitle>
              </ContentBox>
            </ContentsEl>
          </ExhibitionBox>
        )}
        {/* SEP 6 - CONFERENCE */}
        {selectedDay === 1 && selectedType === 1 && (
          <ConferenceBox>
            <TimeText>14:30 - 15:00</TimeText>
            <ContentsEl>
              <ContentBox>
                <ContentTitle>Opening Ceremony</ContentTitle>
              </ContentBox>
            </ContentsEl>
            <br/>
            <TimeText>15:00 - 16:30</TimeText>
            <ContentsEl>
              <ContentBox>
                <ContentTitle>Section 3: #Integrity</ContentTitle>
                <ContentSubTitle>Heal the ailments of the world with art</ContentSubTitle>
              </ContentBox>
            </ContentsEl>
            <br/>
            <TimeText>16:30 - 17:00</TimeText>
            <ContentsEl>
              <ContentBox>
                <ContentTitle>Break</ContentTitle>
              </ContentBox>
            </ContentsEl>
            <br/>
            <TimeText>17:00 - 19:00</TimeText>
            <ContentsEl>
              <ContentBox>
                <ContentTitle>Section 4: #Harmony</ContentTitle>
                <ContentSubTitle>Bring lights together to brighten our world</ContentSubTitle>
              </ContentBox>
            </ContentsEl>
          </ConferenceBox>
        )}
        {/* SEP 6 - PARTY */}
        {selectedDay === 1 && selectedType === 2 && (
          <PartyBox>
            <TimeText>21:00 - 21:30</TimeText>
            <ContentsEl>
              <ContentBox>
                <ContentTitle>Art Performance</ContentTitle>
              </ContentBox>
            </ContentsEl>
            <br/>
            <TimeText>21:30 - 03:00</TimeText>
            <ContentsEl>
              <ContentBox>
                <ContentTitle>
                  Seoul Art Night + Special Performance
                  <br />
                  (Co-hosted by Gallery Hyundai)
                </ContentTitle>
                <ContentSubTitle>*Frieze Seoul Gallery Night</ContentSubTitle>
              </ContentBox>
            </ContentsEl>
          </PartyBox>
        )}
        {/* SEP 7 - EXHIBITION */}
        {selectedDay === 2 && selectedType === 0 && (
          <ExhibitionBox>
            <TimeText>10:00 - 12:00</TimeText>
            <ContentsEl>
              <ContentBox>
                <ContentTitle>
                  SEOUL ART TOUR COURSE 1 :
                  <br />
                  <br />
                  Théâtre des Lumieres
                </ContentTitle>
              </ContentBox>
            </ContentsEl>
            <br/>
            <TimeText>13:00 - 18:00</TimeText>
            <ContentsEl>
              <ContentBox>
                <ContentTitle>
                  SEOUL ART TOURCOURSE 2 :<br />
                  <br />
                  Uncommon Gallery
                  <br />
                  <br />
                  National Museum of Modern and Contemporary Art
                  <br />
                  <br />
                  Art Center Nabi
                </ContentTitle>
              </ContentBox>
            </ContentsEl>
            <br/>
            <TimeText>18:00 - 21:00</TimeText>
            <ContentsEl>
              <ContentBox>
                <ContentTitle>
                  SEOUL ART TOUR COURSE 3 :<br />
                  <br />
                  National Museum of Korea<br/><br/>
                  ARA Art Center
                </ContentTitle>
              </ContentBox>
            </ContentsEl>
          </ExhibitionBox>
        )}
      </BottomEl>
    </SchedulePartEl>
  );
}

export default SchedulePartMobile;
