import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const FeaturesPartEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 120px;
  margin-top: 120px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 80px;
    margin-top: 80px;
  }
`;

const FeatureItemEl = styled.div`
  display: flex;
  gap: 124px;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    gap: 24px;
  }
`;

const FeatureImg = styled.img`
  width: 524px;
  height: 393px;
  border-radius: 16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 343px;
    height: 257px;
  }
`;

const FeatureExplainEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 24px;
  }
`;

const Title = styled.span`
  color: #fff;
  font-family: Parabole;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  text-transform: uppercase;
  transition: 1s;
  clip-path: ${(props) =>
    props.$isShow
      ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
      : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"};
  transform: ${(props) => (props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 40px;
    line-height: 48px; /* 120% */
  }
`;

const Explain = styled.span`
  width: 631px;
  color: #fff;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

const PDFLink = styled.a``;

const PDFEl = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const PDFText = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
`;

const PDFButton = styled.img`
  width: 40px;
  height: auto;
  cursor: pointer;
`;

function FeaturesPart() {
  const [isTitle1Show, setIsTitle1Show] = useState(false);
  const [title1Ref, title1InView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [isTitle2Show, setIsTitle2Show] = useState(false);
  const [title2Ref, title2InView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [isTitle3Show, setIsTitle3Show] = useState(false);
  const [title3Ref, title3InView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (title1InView) {
      setIsTitle1Show(true);
    }
  }, [title1InView]);

  useEffect(() => {
    if (title2InView) {
      setIsTitle2Show(true);
    }
  }, [title2InView]);

  useEffect(() => {
    if (title3InView) {
      setIsTitle3Show(true);
    }
  }, [title3InView]);
  return (
    <FeaturesPartEl>
      <FeatureItemEl>
        <FeatureImg src={"/images/the_party.png"} />
        <FeatureExplainEl>
          <Title ref={title1Ref} $isShow={isTitle1Show}>
            The Party
          </Title>
          <Explain>
            Grand networking event involving domestic and international artists, builders,
            platforms, and institutions.
          </Explain>
        </FeatureExplainEl>
      </FeatureItemEl>
      <FeatureItemEl>
        <FeatureImg src={"/images/arttour.png"} />
        <FeatureExplainEl>
          <Title ref={title2Ref} $isShow={isTitle2Show}>
            Seoul Art tour
          </Title>
          <Explain>
            We offer three distinct courses, providing diverse experiences: explore digital, crypto,
            and traditional art at cultural institutions or unique venues while engaging with
            artists or experts. It will immerse participants in the vibrant art scene of Seoul
            through these specialized thematic courses.
          </Explain>

          <PDFEl>
            <PDFText>view COURSES (PDF)</PDFText>
            <PDFLink
              href={"https://cryptoartseoul.com/public/[SeoulArtTour]Cryptoart_0828.pdf"}
              target="_blank"
              rel="noreferrer"
            >
              <PDFButton src={"/icons/pdf_button.svg"} />
            </PDFLink>
          </PDFEl>
        </FeatureExplainEl>
      </FeatureItemEl>
      <FeatureItemEl>
        <FeatureImg src={"/images/mobility.png"} />
        <FeatureExplainEl>
          <Title ref={title3Ref} $isShow={isTitle3Show}>
            Mobility Experience
          </Title>
          <Explain>
            Our shuttle buses are equipped with a mobile exhibition by Mix.Audio titled, ‘Block
            Music City,’ which introduces visitors to the theme ‘Hardforking Humanity’ on their way
            to Crypto Art Seoul 2023.
          </Explain>
        </FeatureExplainEl>
      </FeatureItemEl>
      <a id="visit"></a>
    </FeaturesPartEl>
  );
}

export default FeaturesPart;
