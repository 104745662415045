import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";

const FooterEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  @media (max-width: ${ScreenSizes.mobile}) {
    margin-top: 80px;
  }
`;

const LogoImg = styled.img`
  margin-bottom: 40px;
  width:185px;
  @media (max-width: ${ScreenSizes.mobile}) {
    margin-bottom: 24px;
  }
`;

const ExplainText = styled.span`
  width: 848px;
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
  margin-bottom: 95px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 343px;
    font-size: 14px;
    line-height: 21px; /* 150% */
    margin-bottom: 80px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.24;
  background: #fff;
`;

const Contact = styled.span`
  color: #fff;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin: 80px 0px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 21px; /* 150% */
    margin: 40px 0px;
  }
`;

const HighLight = styled.a`
  color: #3767ff;
  text-decoration: none;
`;

const LinkText = styled.a`
  color:inherit;
`;

function Footer() {
  return (
    <FooterEl>
      <a href={"https://www.sto.or.kr/"} target="_blank" rel="noreferrer">
        <LogoImg src={"/logos/footer_logo_2.png"} />
      </a>
      <ExplainText>
        CRYPTO ART SEOUL 2023 is an official event sanctioned and supported by
        Seoul Tourism Organization to be held annually for the next five years.<br/>
        Explore the charm of Seoul, where Crypto Art Seoul is being held!<br/>
        More information at <LinkText href={"https://english.visitseoul.net/index"} target="_blank" rel="noreferrer">VisitSeoul.net</LinkText>.
      </ExplainText>
      <Line />
      <Contact>
        For any and all inquiries, please contact{" "}
        <HighLight href={"mailto:info@cryptoartseoul.com"}>info@cryptoartseoul.com</HighLight>
      </Contact>
    </FooterEl>
  );
}

export default Footer;
