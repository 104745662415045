import { configureStore } from "@reduxjs/toolkit";

import serverReducer from "./serverSlice";
import componentReducer from "./componentSlice";
import mintReducer from "./web3MintSlice";

const store = configureStore({
    reducer: {
      server: serverReducer,
      component: componentReducer,
      mint: mintReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  
  export default store;