import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import HeaderMenuItem from "./HeaderMenuItem";

const HeaderEl = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 88px;
  padding-top: ${(props) => (props.$isScrolled ? "0px" : "20px")};
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 3;
  transition: 0.5s;
  transition-delay: 0.1s;
  opacity: ${(props) => (props.$show ? "1" : "0")};
  background: ${(props) =>
    props.$isScrolled
      ? "linear-gradient(rgba(0,0,0,1),rgba(0,0,0,1),rgba(0,0,0,0.9),rgba(0,0,0,0.6),rgba(0,0,0,0))"
      : "none"};
  //background: linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0.9),rgba(0,0,0,0.8),rgba(30,30,30,0.7),rgba(50,50,50,0.5),rgba(50,50,50,0.3));
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 80px;
    align-items: center;
    background: ${(props) =>
      props.$isScrolled
        ? "linear-gradient(rgba(0,0,0,1),rgba(0,0,0,1),rgba(0,0,0,0.9),rgba(0,0,0,0.6),rgba(0,0,0,0))"
        : "none"};
  }
`;

const HeaderWrapperEl = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0px 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 0px 16px;
  }
`;

const LogoBox = styled.div`
  display: flex;
  width: 40px;
  justify-content: flex-start;
`;

const HeaderLogo = styled.img`
  transition: 0.5s;
  transition-delay: 0.1s;
  height: ${(props) => (props.$isScrolled ? "20px" : "32px")};
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 24px;
  }
`;

// const HeaderMenuEl = styled.ul`
//   display: flex;
//   flex-direction: row;
//   gap: 120px;
//   list-style: none;
//   margin-bottom: 10px;
//   @media (max-width: ${ScreenSizes.mobile}) {
//     display: none;
//   }
// `;

const HeaderMenuEl = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-bottom: 10px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const MenuItem = styled.li`
  color: #fff;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
`;

const SNSMenuEl = styled.ul`
  display: flex;
  list-style-type: none;
  gap: 20px;
  padding: 0px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const MobileSNSMenuEl = styled.ul`
  display: flex;
  list-style-type: none;
  gap: 20px;
  padding: 0px;
`;

const SNSItem = styled.li``;

const SNSIcon = styled.img`
  height: 12px;
  width: auto;
`;

const SNSLink = styled.a``;

const MobileRightEl = styled.div`
  display: flex;
  gap: 24px;
`;

const MobileMenuButton = styled.img`
  cursor: pointer;
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
  }
`;

const MobileMenuEl = styled.div`
  position: absolute;
  top: 68px;
  right: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(12px);
  border: 1px solid white;
`;

const MobileMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  padding: 0px;
  margin: 0px;
`;

const TicketsButton = styled.button`
  display: flex;
  height: 40px;
  padding: 15px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 2000px;
  border: 1px solid #fff;
  background: none;
  cursor: pointer;

  /* FONT 2 */
  color: #fff;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 8px 16px;
  }
`;

const BookMark = styled.a`
  text-decoration: none;
  position: relative;
`;

function Header({ showComponents, currentSection, headerScrollY }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  useEffect(() => {
    console.log("currentSection : ", currentSection);
  }, [currentSection]);
  return (
    <HeaderEl $show={showComponents} $isScrolled={headerScrollY !== 0}>
      <HeaderWrapperEl>
        <BookMark href="#top">
          <LogoBox>
            <HeaderLogo
              src={"/logos/Logo_Horizontal.svg"}
              $isScrolled={headerScrollY !== 0}
            />
          </LogoBox>
        </BookMark>
        <HeaderMenuEl>
          <BookMark href="#agenda">
            <HeaderMenuItem isSelected={currentSection === 1}>
              Agenda
            </HeaderMenuItem>
          </BookMark>
          <BookMark href="#visit">
            <HeaderMenuItem isSelected={currentSection === 2}>
              visit
            </HeaderMenuItem>
          </BookMark>
          <BookMark href="#involved">
            <HeaderMenuItem isSelected={currentSection === 3}>
              get involved
            </HeaderMenuItem>
          </BookMark>
          <BookMark href="#aboutus">
            <HeaderMenuItem isSelected={currentSection === 4}>
              about us
            </HeaderMenuItem>
          </BookMark>
        </HeaderMenuEl>
        <SNSMenuEl>
          <SNSItem>
            <SNSLink
              href={"https://twitter.com/cryptoartseoul"}
              target="_blank"
              rel="noreferrer"
            >
              <SNSIcon src="/icons/twitter.svg" />
            </SNSLink>
          </SNSItem>
          <SNSItem>
            <SNSLink
              href={"https://instagram.com/cryptoartseoul"}
              target="_blank"
              rel="noreferrer"
            >
              <SNSIcon src="/icons/instagram.svg" />
            </SNSLink>
          </SNSItem>
          <SNSItem>
            <SNSLink
              href={"https://t.me/+9iYELY8vqeJiMjY9"}
              target="_blank"
              rel="noreferrer"
            >
              <SNSIcon src="/icons/telegram.svg" />
            </SNSLink>
          </SNSItem>
        </SNSMenuEl>
        <MobileRightEl>
          <BookMark href="#tickets">
            <TicketsButton>Tickets</TicketsButton>
          </BookMark>
          {isMobileMenuOpen ? (
            <MobileMenuButton
              onClick={(e) => {
                setIsMobileMenuOpen(false);
              }}
              src={"/icons/Close.svg"}
            />
          ) : (
            <MobileMenuButton
              onClick={(e) => {
                setIsMobileMenuOpen(true);
              }}
              src={"/icons/mobile_menu.svg"}
            />
          )}
        </MobileRightEl>
        {isMobileMenuOpen && (
          <MobileMenuEl>
            <MobileMenuList>
              <BookMark href="#agenda">
                <MenuItem>Agenda</MenuItem>
              </BookMark>
              <BookMark href="#visit">
                <MenuItem>visit</MenuItem>
              </BookMark>
              <BookMark href="#involved">
                <MenuItem>get involved</MenuItem>
              </BookMark>
              <BookMark href="#aboutus">
                <MenuItem>about us</MenuItem>
              </BookMark>
              <MobileSNSMenuEl>
                <SNSItem>
                  <SNSLink
                    href={"https://twitter.com/cryptoartseoul"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SNSIcon src="/icons/twitter.svg" />
                  </SNSLink>
                </SNSItem>
                <SNSItem>
                  <SNSLink
                    href={"https://instagram.com/cryptoartseoul"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SNSIcon src="/icons/instagram.svg" />
                  </SNSLink>
                </SNSItem>
                <SNSItem>
                  <SNSLink
                    href={"https://t.me/+9iYELY8vqeJiMjY9"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SNSIcon src="/icons/telegram.svg" />
                  </SNSLink>
                </SNSItem>
              </MobileSNSMenuEl>
            </MobileMenuList>
          </MobileMenuEl>
        )}
      </HeaderWrapperEl>
    </HeaderEl>
  );
}

export default Header;
