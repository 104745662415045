import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { ScreenSizes } from '../../../styles/Themes';

const PartnersPartEl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 72px;
  margin-top:120px;
  @media (max-width: ${ScreenSizes.mobile}) {
    margin-top: 80px;
    flex-direction: column;
    gap: 24px;
  }
`;

const Title = styled.span`
  width: 360px;
  color: #fff;
  font-family: Parabole;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  text-transform: uppercase;
  transition: 1s;
  clip-path:${(props)=>(props.$isShow ? "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)" : "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)")};
  transform:${(props)=>(props.$isShow ? "translate(0,0%)" : "translate(0,100px)")};
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    text-align: left;
    font-size: 40px;
    line-height: 48px; /* 120% */
    text-transform: uppercase;
  }
`;

const CompanyListWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CompanyListEl = styled.div`
  width: 850px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    gap: 8px;
  }
`;

const CompanyBox = styled.div`
  width: 272px;
  height: 272px;
  border-radius: 16px;
  background: #141414;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 109px;
    height: 109px;
    border-radius: 16px;
  }
`;

const CompanyImgPC = styled.img`
  display: inline-block;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const CompanyImgMobile = styled.img`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
  }
`;

function PartnersPart() {
  const [isTitleShow, setIsTitleShow] = useState(false);
  const [titleRef, titleInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if(titleInView){
    console.log("title show");
    setIsTitleShow(true);
  }
  }, [titleInView]);
  return (
    <PartnersPartEl>
      <Title ref={titleRef} $isShow={isTitleShow}>Partners</Title>
      <CompanyListWrapper>
        <CompanyListEl>
          <a
            href={"https://metamask.io/"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"144px"} src={"/logos/metamask.png"} />
              <CompanyImgMobile width={"57.6px"} src={"/logos/metamask.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://www.sandbox.game/"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"183px"} src={"/logos/sandbox.png"} />
              <CompanyImgMobile width={"73.3px"} src={"/logos/sandbox.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://altava.com/"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"157px"} src={"/logos/AltavaGroup_LogoMark_01.png"} />
              <CompanyImgMobile width={"62.9px"} src={"/logos/AltavaGroup_LogoMark_01.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://www.bluecanvas.com"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"121px"} src={"/logos/bluecanvas.png"} />
              <CompanyImgMobile width={"48.4px"} src={"/logos/bluecanvas.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://ainetwork.ai"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"146px"} src={"/logos/ai_network.png"} />
              <CompanyImgMobile width={"58.4px"} src={"/logos/ai_network.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://www.sewernation.xyz/"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"108.9px"} src={"/logos/sewer.png"} />
              <CompanyImgMobile width={"43.6px"} src={"/logos/sewer.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://mix.audio"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"144px"} src={"/logos/mixaudio.png"} />
              <CompanyImgMobile width={"57.6px"} src={"/logos/mixaudio.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://dada.art"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"122.5px"} src={"/logos/dada.png"} />
              <CompanyImgMobile width={"49px"} src={"/logos/dada.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://joyn.xyz"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"95px"} src={"/logos/joyn.png"} />
              <CompanyImgMobile width={"38px"} src={"/logos/joyn.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://www.mocda.org/"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"146.2px"} src={"/logos/mocda.png"} />
              <CompanyImgMobile width={"58.5px"} src={"/logos/mocda.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://nftfactoryparis.com/"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"160px"} src={"/logos/nftfactoryparis.png"} />
              <CompanyImgMobile width={"64px"} src={"/logos/nftfactoryparis.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://proof.xyz"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"94px"} src={"/logos/proof.png"} />
              <CompanyImgMobile width={"37.6px"} src={"/logos/proof.png"} />
            </CompanyBox>
          </a>
          <a
            href={"https://www.verticalcrypto.art/"}
            target="_blank"
            rel="noreferrer"
          >
            <CompanyBox>
              <CompanyImgPC width={"86px"} src={"/logos/verticalcrypto.png"} />
              <CompanyImgMobile width={"34.4px"} src={"/logos/verticalcrypto.png"} />
            </CompanyBox>
          </a>
        </CompanyListEl>
      </CompanyListWrapper>
    </PartnersPartEl>
  );
}

export default PartnersPart;
